import React from "react";
import Dropdown from "../Dropdown/Dropdown";
import {
  Map as LeafletMap,
  Marker,
  Popup,
  TileLayer,
  ZoomControl,
} from "react-leaflet";
import FullscreenControl from "react-leaflet-fullscreen";
import { divIcon } from "leaflet";

export default function ContactDetail({ contactDetail }) {
  const {
    address,
    phone,
    web,
    facebook,
    twitter,
    linkedIn,
    webLink,
    facebookLink,
    twitterLink,
    linkedInLink,
    latitude,
    longitude,
  } = contactDetail;

  const icon = divIcon({
    className: "map-label",
    html: `<div class="map-marker"><div class="marker-icon"><span class="count-label"></span></div></div>`,
    iconSize: null,
  });

  return (
    contactDetail && (
      <Dropdown
        title={<h2>Coordonnées</h2>}
        content={
          <div className='row'>
            <div className='contact-detail'>
              <div className='row'>
                <div className='col'>Adresse postale</div>
                <div className='col'>
                  {address.toLowerCase() || "Non communiqué"}
                </div>
              </div>
              <div className='row'>
                <div className='col'>Téléphone</div>
                <div className='col'>{phone || "Non communiqué"}</div>
              </div>
              <div className='row'>
                <div className='col'>Site web</div>
                <div className='col'>
                  <div className='col'>
                    {web ? (
                      <a
                        href={webLink}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {web}
                      </a>
                    ) : (
                      "Non communiqué"
                    )}
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col'>Facebook</div>
                <div className='col'>
                  {facebook ? (
                    <a
                      href={facebookLink}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {facebook}
                    </a>
                  ) : (
                    "Non communiqué"
                  )}
                </div>
              </div>
              <div className='row'>
                <div className='col'>Twitter</div>
                <div className='col'>
                  {twitter ? (
                    <a
                      href={twitterLink}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {twitter}
                    </a>
                  ) : (
                    "Non communiqué"
                  )}
                </div>
              </div>
              <div className='row'>
                <div className='col'>LinkedIn</div>
                <div className='col'>
                  {linkedIn ? (
                    <a
                      href={linkedInLink}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {linkedIn}
                    </a>
                  ) : (
                    "Non communiqué"
                  )}
                </div>
              </div>
            </div>
            {latitude && longitude && (
              <LeafletMap
                className='map-div'
                center={[latitude, longitude]}
                zoom={13}
                zoomControl={false}
                scrollWheelZoom={false}
              >
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                />
                <FullscreenControl
                  forceSeparateButton={true}
                  content={"<span class='icon icon-full-2'></span>"}
                  position='topright'
                />
                <ZoomControl
                  position='bottomright'
                  zoomInText={"<span class='icon icon-plus-sm'></span>"}
                  zoomOutText={"<span class='icon icon-minus'></span>"}
                />
                <Marker position={[latitude, longitude]} icon={icon}>
                  <Popup>{address}</Popup>
                </Marker>
              </LeafletMap>
            )}
          </div>
        }
      />
    )
  );
}
