import React, { Component } from "react";
import { Col, Row } from "react-flexbox-grid";
import Select from "react-select";
import axios from "axios";
import "./CreatePreFilterComponent.scss";

import Dropzone from "react-dropzone";

const companyTypes = [
  { value: "siren", label: "Siren" },
  { value: "siret", label: "Siret" },
];

const companyTypesArr = ["siren", "siret"];

const allColumns = {
  siren: { value: "siren", label: "Siren" },
  siret: { value: "siret", label: "Siret" },
  mail: { value: "mail", label: "Email" },
  telephone: { value: "telephone", label: "Téléphone" },
  filtre_general_activite: {
    value: "filtre_general_activite",
    label: "Code activité",
  },
  filtre_general_codpos: {
    value: "filtre_general_codpos",
    label: "Code postal",
  },
  filtre_general_dep: {
    value: "filtre_general_dep",
    label: "Code département",
  },
  //TODO Uncomment later after prod 16/09/2019
  // filtre_general_codeCommune: {
  //     value: "filtre_general_codeCommune",
  //     label: "Code commune (INSEE)"
  // },
  filtre_general_coordonnee: {
    value: "filtre_general_coordonnee",
    label: "Coordonnées XY",
  },
};

const columns = {
  mail: { value: "mail", label: "Email" },
  phone: { value: "telephone", label: "Téléphone" },
  filtre_general_activite: {
    value: "filtre_general_activite",
    label: "Code activité",
  },
  filtre_general_codpos: {
    value: "filtre_general_codpos",
    label: "Code postal",
  },
  filtre_general_dep: {
    value: "filtre_general_dep",
    label: "Code département",
  },
  //TODO Uncomment later after prod 16/09/2019
  // filtre_general_codeCommune: {
  //     value: "filtre_general_codeCommune",
  //     label: "Code commune (INSEE)"
  // },
  filtre_general_coordonnee: {
    value: "filtre_general_coordonnee",
    label: "Coordonnées XY (Limité à 30 coordonnées)",
  },
};

export default class CreatePreFilterComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      currentStep: 1,
      filterName: "",
      acceptedFile: null,
      companyId: false,
      typeCompanyId: "",
      selectedColumns: [],
      selectedColumnsValues: {},
      serverFileName: "",
      fileHeaders: [],
    };

    this.handleFilterName = this.handleFilterName.bind(this);
    this.goPrevStep = this.goPrevStep.bind(this);
    this.goNextStep = this.goNextStep.bind(this);

    this.handleCompanyCheckbox = this.handleCompanyCheckbox.bind(this);
    this.handleCompanyIDChange = this.handleCompanyIDChange.bind(this);
    this.handleColumnCheckbox = this.handleColumnCheckbox.bind(this);
    this.handleColumnSelectChange = this.handleColumnSelectChange.bind(this);

    this.onDrop = this.onDrop.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.clearAllData = this.clearAllData.bind(this);
  }

  componentWillUnmount() {
    // Need to clear all things
    if (this.state.currentStep === 5) {
      this.props.reloadItems();
    }
    this.clearAllData();
  }

  clearAllData() {
    this.setState({
      currentStep: 1,
      filterName: "",
      acceptedFile: null,
      companyId: false,
      typeCompanyId: "",
      selectedColumns: [],
      serverFileName: "",
      fileHeaders: [],
    });
    this.props.updateLoader(false);
  }

  handleFilterName(e) {
    this.setState({
      filterName: e.target.value,
    });
  }

  handleCompanyCheckbox(e) {
    const newState = {
      companyId: e.target.checked,
    };
    if (!e.target.checked) {
      newState.typeCompanyId = "";

      const selectedList = this.state.selectedColumnsValues;

      Object.keys(selectedList).forEach((k) => {
        if (companyTypesArr.indexOf(k) > -1) {
          delete selectedList[k];
        }
      });

      newState.selectedColumnsValues = selectedList;
    }
    this.setState(newState);
  }

  handleCompanyIDChange(e) {
    const selectedList = this.state.selectedColumnsValues;
    const newObj = {};

    Object.keys(selectedList).forEach((k) => {
      if (companyTypesArr.indexOf(k) > -1) {
        delete selectedList[k];
      }
    });

    newObj[e.value] = { value: "" };

    this.setState({
      typeCompanyId: e,
      selectedColumnsValues: { ...newObj, ...selectedList },
    });
  }

  handleColumnSelectChange(e, k) {
    const list = this.state.selectedColumnsValues;

    list[k].value = e;

    this.setState({
      selectedColumnsValues: list,
    });
  }

  getFilteredOptions() {
    const options = [];

    this.state.headersOptions.forEach((h) => {
      const item = h;
      let disabled = false;
      Object.keys(this.state.selectedColumnsValues).forEach((k) => {
        if (this.state.selectedColumnsValues[k].value) {
          if (this.state.selectedColumnsValues[k].value.value === item.value) {
            disabled = true;
          }
        }
      });

      item.disabled = disabled;
      options.push(item);
    });

    return options;
  }

  sendFile(nextStep) {
    const data = new FormData();
    data.append("file", this.state.acceptedFile);

    this.props.updateLoader(true);
    axios
      .post("/api/mlist/filters/import?filterType=PREFILTER", data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((res) => {
        const fileHeaders = {};
        const headersOptions = [];

        res.data.headers.forEach((h) => {
          const id = h.columnName;
          fileHeaders[id] = h;
          headersOptions.push({ value: id, label: id });
        });

        this.setState(
          {
            serverFileName: res.data.fileName,
            fileHeaders: fileHeaders,
            headersOptions: headersOptions,
            currentStep: nextStep,
          },
          () => {
            this.props.updateLoader(false);
          }
        );
      });
  }

  savePrefilter(nextStep) {
    const filters = [];

    Object.keys(this.state.selectedColumnsValues).forEach((k) => {
      const item = this.state.selectedColumnsValues[k];
      filters.push({
        columnIndex: this.state.fileHeaders[item.value.value].columnIndex,
        columnName: this.state.fileHeaders[item.value.value].columnName,
        columnFilter: k,
      });
    });

    this.props.updateLoader(true);
    axios
      .post(
        "/api/mlist/web/filters",
        {
          name: this.state.filterName,
          accountId: this.props.accountId,
          fileId: this.state.serverFileName,
          options: filters,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        this.setState(
          {
            currentStep: nextStep,
            filterName: "",
            acceptedFile: null,
            companyId: false,
            typeCompanyId: "",
            selectedColumns: [],
            serverFileName: "",
            fileHeaders: [],
          },
          () => {
            this.props.updateLoader(false);
          }
        );
      });
  }

  handleColumnCheckbox(e) {
    const selectedColumns = this.state.selectedColumns;
    const selectedColumnsValues = this.state.selectedColumnsValues;
    const val = e.target.value;
    if (e.target.checked) {
      selectedColumns.push(val);
      selectedColumnsValues[val] = { value: "" };
    } else {
      const index = selectedColumns.indexOf(val);
      if (index > -1) {
        selectedColumns.splice(index, 1);
      }
      delete selectedColumnsValues[val];
    }

    this.setState({
      selectedColumns,
      selectedColumnsValues,
    });
  }

  getModalHeader() {
    return (
      <header>
        <span>Créer un préfiltre</span>
        {this.getStepsList()}
      </header>
    );
  }

  getStepsList() {
    return (
      <ul className='stepsList'>
        <li className={"step " + (this.state.currentStep >= 1 ? "active" : "")}>
          <span>1</span>
          {this.state.currentStep > 1 ? (
            <span className='checkmark'>
              <span className='icon icon-confirm'></span>
            </span>
          ) : null}
        </li>
        <li className={"step " + (this.state.currentStep >= 2 ? "active" : "")}>
          <span>2</span>
          {this.state.currentStep > 2 ? (
            <span className='checkmark'>
              <span className='icon icon-confirm'></span>
            </span>
          ) : null}
        </li>
        <li className={"step " + (this.state.currentStep >= 3 ? "active" : "")}>
          <span>3</span>
          {this.state.currentStep > 3 ? (
            <span className='checkmark'>
              <span className='icon icon-confirm'></span>
            </span>
          ) : null}
        </li>
        <li className={"step " + (this.state.currentStep >= 4 ? "active" : "")}>
          <span>4</span>
          {this.state.currentStep > 4 ? (
            <span className='checkmark'>
              <span className='icon icon-confirm'></span>
            </span>
          ) : null}
        </li>
        <li className={"step " + (this.state.currentStep >= 5 ? "active" : "")}>
          <span>5</span>
        </li>
      </ul>
    );
  }

  onDrop(acceptedFiles) {
    this.setState({
      acceptedFile: acceptedFiles[0],
    });
  }

  removeFile(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      acceptedFile: null,
    });
  }

  getDragFileZone() {
    return (
      <Dropzone onDrop={this.onDrop} accept='.csv'>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div
            {...getRootProps({
              className: "dropzone " + (isDragActive ? "active-area" : ""),
            })}
          >
            <input {...getInputProps()} />
            <div className='drop-container'>
              <div className='title-icon'>
                <span
                  className={
                    "icon " +
                    (this.state.acceptedFile ? "icon-confirm" : "icon-upload")
                  }
                ></span>
              </div>
              <div className='description'>
                <h3 className='desc-title'>
                  {this.state.acceptedFile
                    ? `Fichier importé avec succès`
                    : `Importer le fichier`}
                </h3>
                <p className='desc-text'>
                  {this.state.acceptedFile
                    ? `${this.state.acceptedFile.name}`
                    : `Format d'import CSV`}
                  {this.state.acceptedFile ? (
                    <span className='remove-file' onClick={this.removeFile}>
                      <span className='icon icon-remove'></span>
                    </span>
                  ) : null}
                </p>
              </div>
            </div>
          </div>
        )}
      </Dropzone>
    );
  }

  getThirdPartSelection() {
    return (
      <div className='selection-holder'>
        <Row>
          <Col xs={12}>
            <div className='company-id-check'>
              <label>
                <div
                  className={
                    "custom-checkbox " + (this.state.companyId ? "checked" : "")
                  }
                >
                  <input
                    onChange={this.handleCompanyCheckbox}
                    type='checkbox'
                    checked={this.state.companyId}
                  />
                </div>
                Identifiant entreprise
              </label>
            </div>
          </Col>
          <Col xs={9}>
            <Select
              className='select company-id-select'
              classNamePrefix='react-select'
              isDisabled={!this.state.companyId}
              value={this.state.typeCompanyId}
              placeholder="Choisir un type d'identifiant"
              onChange={this.handleCompanyIDChange}
              options={companyTypes}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className='checkboxes-holder'>
              {Object.keys(columns).map((c, i) => {
                return (
                  <div className='checkbox-item' key={i}>
                    <label>
                      <div
                        className={
                          "custom-checkbox " +
                          (this.isColumnSelected(columns[c].value)
                            ? "checked"
                            : "")
                        }
                      >
                        <input
                          onChange={this.handleColumnCheckbox}
                          type='checkbox'
                          value={columns[c].value}
                          checked={this.isColumnSelected(columns[c].value)}
                        />
                      </div>
                      {columns[c].label}
                    </label>
                  </div>
                );
              })}
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  getFourthPartSelection() {
    return (
      <div className='selection-holder'>
        <Row>
          <Col xs={9}>
            <ul className='columns-list'>
              {Object.keys(this.state.selectedColumnsValues).map((k, i) => {
                return (
                  <li className='column-item' key={i}>
                    <p className='column-title'>{allColumns[k].label}</p>
                    <Select
                      className='select'
                      classNamePrefix='react-select'
                      value={this.state.selectedColumnsValues[k].value}
                      placeholder='Choisir une colonne'
                      onChange={(e) => this.handleColumnSelectChange(e, k)}
                      isOptionDisabled={(option) => option.disabled}
                      options={this.getFilteredOptions()}
                    />
                  </li>
                );
              })}
            </ul>
          </Col>
        </Row>
      </div>
    );
  }

  isColumnSelected(name) {
    return this.state.selectedColumns.indexOf(name) > -1;
  }

  getStepContent() {
    switch (this.state.currentStep) {
      case 2:
        return (
          <React.Fragment>
            <h3 className='step-title'>Chargement de votre fichier</h3>
            {this.getDragFileZone()}
          </React.Fragment>
        );
      case 3:
        return (
          <React.Fragment>
            <h3 className='step-title'>
              Sélectionnez vos données de préfiltre
            </h3>
            <p className='step-subtitle'>
              Cochez les data que vous souhaitez utiliser pour la création de
              vorte propre base de données. Chaque donnée sélectionnée
              constituera un préfiltre que vous pourrez utiliser.
            </p>
            {this.getThirdPartSelection()}
          </React.Fragment>
        );
      case 4:
        return (
          <React.Fragment>
            <h3 className='step-title no-m'>
              Identifiez les données de préfiltre
            </h3>
            <p className='step-subtitle'>
              Sélectionner pour chaque donnée de préfiltre à créer, la colonne
              corespondante dans votre fichier.
            </p>
            {this.getFourthPartSelection()}
          </React.Fragment>
        );
      case 5:
        return (
          <p>
            Vous avez terminé la création de votre préfiltre, ce dernier sera
            disponible aprés traitement directement à I'étape de ciblage.
          </p>
        );
      default:
        return (
          <React.Fragment>
            <h3 className='step-title'>Nommer votre préfiltre</h3>
            <input
              type='text'
              name='filter-name'
              id='filter-name'
              value={this.state.filterName}
              onChange={this.handleFilterName}
              placeholder='Nom du fichier...'
            />
          </React.Fragment>
        );
    }
  }

  getStepButtons() {
    if (this.state.currentStep === 5) {
      return null;
    }

    return (
      <div className='actions-holder'>
        <button
          className={
            "btn btn-secondary " +
            (this.state.currentStep === 1 ? "disabled" : "")
          }
          onClick={this.goPrevStep}
        >
          Précédent
        </button>
        <button
          className={
            "btn btn-primary " + (this.disableNextBtn() ? "disabled" : "")
          }
          onClick={this.goNextStep}
        >
          Suivant
        </button>
      </div>
    );
  }

  goPrevStep() {
    const prevStep = this.state.currentStep - 1;
    switch (prevStep) {
      case 3:
        const values = {};
        Object.keys(this.state.selectedColumnsValues).forEach((k) => {
          values[k] = { value: "" };
        });
        this.setState({
          selectedColumnsValues: values,
        });
        break;
      case 2:
        this.setState({
          companyId: false,
          typeCompanyId: "",
          selectedColumns: [],
          selectedColumnsValues: {},
        });
        break;
      default:
        this.setState({
          acceptedFile: null,
        });
        break;
    }
    this.setState({
      currentStep: prevStep,
    });
  }

  goNextStep() {
    const nextStep = this.state.currentStep + 1;
    switch (this.state.currentStep) {
      case 2:
        this.sendFile(nextStep);
        break;
      case 4:
        this.savePrefilter(nextStep);
        break;
      default:
        this.setState({
          currentStep: nextStep,
        });
        break;
    }
  }

  disableNextBtn() {
    let need2disabled = false;

    switch (this.state.currentStep) {
      case 2:
        need2disabled = !this.state.acceptedFile;
        break;
      case 3:
        if (this.state.companyId) {
          if (!this.state.typeCompanyId) {
            need2disabled = true;
          }
        } else {
          if (this.state.selectedColumns.length === 0) {
            need2disabled = true;
          }
        }
        break;
      case 4:
        Object.keys(this.state.selectedColumnsValues).forEach((k) => {
          if (!this.state.selectedColumnsValues[k].value) {
            need2disabled = true;
          }
        });
        break;
      case 5:
        break;
      default:
        need2disabled = this.state.filterName === "";
    }

    return need2disabled;
  }

  render() {
    return (
      <React.Fragment>
        {this.getModalHeader()}
        <div className='single-tab-modal-content'>
          <div className='step-content'>{this.getStepContent()}</div>
          {this.getStepButtons()}
        </div>
      </React.Fragment>
    );
  }
}
