import React, { useContext } from "react";
import UserBarComponent from "../../shared/components/UserBar/UserBarComponent";
import { UserContext } from "../../shared/context/UserContext";

const UserBar = ({ accountId }) => {
  const [user] = useContext(UserContext);

  if (!user) {
    return null;
  }

  return <UserBarComponent account={user} accountId={accountId} />;
};

export default UserBar;
