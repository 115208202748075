import React, { Component } from "react";

import "./CheckBoxModalComponent.scss";
import ModalFooter from "../../ModalFooter/ModalFooterComponent";
import ModalCondition from "../../ModalCondition/ModalConditionComponent";
import CheckBox from "../../../../../fields/Checkbox/Checkbox";
import { Row, Col } from "react-flexbox-grid";
import ResetFilter from "../reset/ResetFilters";

class CheckBoxView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      values: this.props.content.concepts[0].values,
      optionsChecked: [],
      exclude: false,
      conditionAnd: null,
    };

    this.handler = this.handler.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handlerCondition = this.handlerCondition.bind(this);
    this.handleCheckChildElement = this.handleCheckChildElement.bind(this);
    this.ifItChecked = this.ifItChecked.bind(this);
  }

  componentDidMount() {
    if (this.props.concept && this.props.concept.length > 0) {
      let newChecked = [];

      let filters = this.props.concept[0].filters;

      let newEx = filters[0].not;
      this.setState({
        exclude: newEx,
      });

      filters.forEach((f) => {
        f.expressions.forEach((expression) => {
          const fVal = {
            value: expression,
            label: this.getValueLabel(expression),
          };
          newChecked.push(fVal);
        });
      });

      this.setState({
        optionsChecked: newChecked,
      });
    }
  }

  getValueLabel(val) {
    let label = "";
    if (this.state.values) {
      this.state.values.forEach((v) => {
        if (v.value === val) {
          label = v.label;
        }
      });
    }
    return label;
  }

  sendQuery() {
    const optionsCheckedToSend = this.state.optionsChecked.map(
      (option) => option.value
    );
    this.props.updateQuery(
      optionsCheckedToSend.length ? [optionsCheckedToSend] : [],
      this.props.content,
      this.state.exclude,
      this.props.tab,
      this.props.modal,
      this.state.conditionAnd
    );
  }

  handleCheckChildElement(event, elem) {
    let checkedArray = this.state.optionsChecked;
    let index = -1;

    Object.keys(checkedArray).forEach((key) => {
      if (checkedArray[key].value === elem.value) {
        index = key;
      }
    });

    if (index > -1) {
      checkedArray.splice(index, 1);
    } else {
      checkedArray.push(elem);
    }
    this.setState(
      {
        optionsChecked: checkedArray,
      },
      () => {
        this.sendQuery();
      }
    );
  }

  ifItChecked(i) {
    if (!this.state.optionsChecked) {
      return null;
    }
    const newArray = this.state.optionsChecked.filter((e) => {
      return e.value === i;
    });
    return newArray.length > 0;
  }

  getCheckboxes(checkbox_list) {
    if (!checkbox_list) {
      return null;
    }
    let col_list = this.separateArray(checkbox_list, 8);
    return col_list.map((el, coli) => {
      return (
        <Col md={4} key={coli}>
          <ul>
            {el.map((c, i) => {
              return (
                <li key={i}>
                  <CheckBox
                    handleCheckChildElement={(e) => {
                      this.handleCheckChildElement(e, c);
                    }}
                    checked={this.ifItChecked(c.value)}
                    value={c.value}
                  >
                    {this.ifItChecked(c.value)}
                    {c.label}
                  </CheckBox>
                </li>
              );
            })}
          </ul>
        </Col>
      );
    });
  }

  separateArray(arr, chunk_size) {
    const array = arr;
    const arrayLength = array.length;
    const newarr = [];

    for (let index = 0; index < arrayLength; index += chunk_size) {
      const myChunk = array.slice(index, index + chunk_size);
      newarr.push(myChunk);
    }

    return newarr;
  }

  handler(checked) {
    this.setState(
      {
        exclude: checked,
      },
      () => {
        if (this.state.optionsChecked.length) {
          this.sendQuery();
        }
      }
    );
  }

  handleReset() {
    this.setState(
      {
        optionsChecked: [],
      },
      () => {
        this.sendQuery();
      }
    );
  }

  handlerCondition(condition) {
    this.setState(
      {
        conditionAnd: condition,
      },
      () => {
        if (this.state.optionsChecked.length) {
          this.sendQuery();
        }
      }
    );
  }

  getFooter() {
    return (
      <ModalFooter
        handleChange={this.handler}
        exclude={this.state.exclude}
        option={this.props.content.option}
      />
    );
  }

  getConditionAndOr() {
    if (this.props.content.option.andOr) {
      return <ModalCondition handleChange={this.handlerCondition} />;
    } else {
      return null;
    }
  }

  render() {
    return (
      <>
        {this.getConditionAndOr()}
        <div className='modal'>
          <ResetFilter handleDelete={this.handleReset} />
          <div
            className={
              "modal-content modal-list" +
              (this.state.exclude ? " exclude" : " include")
            }
          >
            <div>
              <Row className='checkboxes-wrapper'>
                <Col xs={12}>
                  <ul className='checkboxes-list'>
                    {this.getCheckboxes(this.state.values)}
                  </ul>
                </Col>
              </Row>
            </div>
          </div>
          {this.getFooter()}
        </div>
      </>
    );
  }
}

export default CheckBoxView;
