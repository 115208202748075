import React, { useState } from "react";
import CreatingStatus from "./components/CreatingStatus/CreatingStatusComponent";
import Footer from "../Footer/FooterComponent";
import ExportSection from "./components/ExportSection/ExportSectionComponent";

export default function CreateListExportPageComponent({
  components,
  goTo,
  accountId,
  updateQuery,
  isMobile,
  handleDownload,
  next,
  back,
}) {
  const [useSolde, setUseSolde] = useState(false);
  const [fileFormat, setFileFormat] = useState(null);
  const [displayUseSolde, setDisplayUseSolde] = useState(
    components.wizardContent.primaryPanel.displayUseSoldeButton
  );

  const getHeader = () =>
    components &&
    components.contentHeader && (
      <CreatingStatus
        item={components.contentHeader}
        goTo={goTo}
        accountId={accountId}
      />
    );

  const updateFileFormatQuery = (step, fileFormat, useSolde) => {
    useSolde && setUseSolde(useSolde);
    fileFormat && setFileFormat(fileFormat);
    updateQuery(step, fileFormat);
  };

  const getFooter = () =>
    components.wizardFooter && (
      <Footer
        handleDownloadExtract={handleDownload}
        item={components.wizardFooter}
        alerts={!useSolde && components.wizardFooter.alerts}
        goNext={next}
        goBack={back}
        forcePotential={
          useSolde &&
          components.wizardContent.primaryPanel.exportCreditInfos.find(
            (e) => e.wallet === "siret"
          ).balance
        }
        showNextStep={fileFormat && components.wizardFooter.showNextStep}
        disableUseSolde={displayUseSolde}
      />
    );

  return (
    components && (
      <div className='h-100'>
        {getHeader()}
        <ExportSection
          item={components.wizardContent}
          updateQuery={updateFileFormatQuery}
          isMobile={isMobile}
          useSolde={useSolde}
          setUseSolde={setUseSolde}
          fileFormat={fileFormat}
          displayUseSolde={displayUseSolde}
          setDisplayUseSolde={setDisplayUseSolde}
        />
        {getFooter()}
      </div>
    )
  );
}
