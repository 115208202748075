import React, { useState, useEffect } from "react";
import Modal from "react-responsive-modal";
import axios from "axios";

import { addItemToExportPackQuery } from "./../../QueryTool";

export default function SelectedCategoryModal({
  selectedCategory,
  closeCategoryModal,
  configHeader,
  configContent,
  isPackSelected,
  updateQuery,
  requestParams,
  packExport,
}) {
  const [exportFields, setExportFields] = useState([]);
  const [excludeFields, setExcludeFields] = useState(
    packExport.options
      ? packExport.options
      : isPackSelected
      ? []
      : configContent.exportFields.filter((e) => !e.mandatory).map((e) => e.id)
  );
  const [potentials, setPotentials] = useState({});

  useEffect(() => {
    if (configHeader.potentialByKey && !potentials[configContent.id]) {
      const query = new URLSearchParams();

      if (requestParams) {
        Object.keys(requestParams).forEach((j) => {
          query.append(j, requestParams[j]);
        });
      }

      axios
        .post(
          `${process.env.REACT_APP_API_URL}getPotentialByKey/?packId=${configContent.id}`,
          query,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((r) => {
          const t = {};
          t[configContent.id] = r.data;
          setPotentials(t);
        });
    }

    setExportFields(
      configContent.exportFields.map((field) => {
        const pots = potentials[configContent.id]
          ? potentials[configContent.id]
          : [];
        const potential = pots[field.id];
        return {
          id: field.id,
          mandatory: field.mandatory,
          label: field.label,
          selected: excludeFields.indexOf(field.id) === -1,
          potential: potential,
        };
      })
    );
  }, [
    configContent.exportFields,
    configHeader,
    configContent.id,
    requestParams,
    excludeFields,
    potentials,
  ]);

  const unselectFieldAll = () => {
    setExcludeFields(exportFields.filter((e) => !e.mandatory).map((e) => e.id));
  };

  const selectFieldAll = () => {
    setExcludeFields([]);
  };

  const unselectField = (fieldId) => {
    setExcludeFields([...excludeFields, fieldId]);
  };

  const selectField = (fieldId) => {
    setExcludeFields(excludeFields.filter((f) => f !== fieldId));
  };

  return (
    <Modal
      open={selectedCategory != null}
      onClose={closeCategoryModal}
      focusTrapped={false}
      center
    >
      <div className='export-category-modal single-tab-modal'>
        {selectedCategory != null && (
          <React.Fragment>
            <header>
              <div className='left-header'>
                <span
                  className={"icon icon-" + configHeader.primaryLayoutIcon}
                />
                <div className={"title-count-container"}>
                  <div className='title-container'>
                    {configHeader.primaryLayoutTitle}
                  </div>
                  <div className='count-container'>
                    {configHeader.primaryLayoutSubTitle}
                  </div>
                </div>
              </div>
              <div className='separator'></div>
              <div className='right-header'>
                <div className='right-header__title'>
                  {configHeader.secondaryLayoutTitle}
                </div>
                <div className='right-header__subtitle'>
                  {configHeader.secondaryLayoutSubTitle}
                </div>
              </div>
            </header>
            <div className='single-tab-modal-content modal-content'>
              <div className='selectAll'>
                <span onClick={selectFieldAll}>Tout sélectionner</span>
                &nbsp;|&nbsp;
                <span onClick={unselectFieldAll}>Tout désélectionner</span>
              </div>
              <div className='modal-content__category-list'>
                {exportFields.map((field) => {
                  let fieldClass = "category-entry-box";
                  let iconMandatory = null;
                  let action = null;
                  if (field.mandatory) {
                    fieldClass = `${fieldClass} category-entry-selected`;
                    iconMandatory = <span className='icon icon-padlock'></span>;
                  } else {
                    if (field.selected) {
                      fieldClass = `${fieldClass} category-entry-selectable  category-entry-selected`;
                      action = () => {
                        unselectField(field.id);
                      };
                    } else {
                      fieldClass = `${fieldClass} category-entry-selectable  category-entry-unselected`;
                      action = () => {
                        selectField(field.id);
                      };
                    }
                  }
                  let fieldDiv = null;
                  if (field.potential) {
                    const classNamePotential = field.selected
                      ? "category-entry-potential category-entry-potential-selected"
                      : "category-entry-potential";
                    fieldDiv = (
                      <div className='category-entry' key={field.id}>
                        <div className={fieldClass} onClick={action}>
                          {iconMandatory}
                          {field.label}
                        </div>
                        <div className={classNamePotential}>
                          {field.potential} ent.
                        </div>
                      </div>
                    );
                  } else {
                    fieldClass = `${fieldClass} category-entry-box-bottom category-entry`;
                    fieldDiv = (
                      <div
                        className={fieldClass}
                        key={field.id}
                        onClick={action}
                      >
                        {iconMandatory}
                        {field.label}
                      </div>
                    );
                  }
                  return fieldDiv;
                })}
              </div>
              <div className='actions-holder'>
                <button
                  className='btn btn-primary'
                  onClick={() => {
                    const query = addItemToExportPackQuery(
                      requestParams.packExports,
                      configContent.id,
                      excludeFields
                    );
                    updateQuery(query);
                    closeCategoryModal();
                  }}
                >
                  Suivant
                </button>
                <button
                  className='btn btn-secondary'
                  onClick={() => {
                    closeCategoryModal();
                  }}
                >
                  Annuler
                </button>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </Modal>
  );
}
