import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Page from "./pages/Page";
import Logout from "./pages/auth0-logout";
import LogoutNoIframe from "./pages/logoutNoIframe";

const styles = {
  routerWrap: {
    display: "block",
    width: "100%",
  },
};

export default function RoutMap({ defaultId, accountId, active, isLoading }) {
  if (defaultId == null || accountId == null) {
    return null;
  }
  return (
    <div className={"router-wrap h-100"} style={styles.routerWrap}>
      <Switch>
        <Route exact path='/logout' render={() => <Logout />} />
        <Route path='/logout-mApp' render={() => <LogoutNoIframe />} />
        <Route
          exact
          path='/'
          render={() => (
            <Redirect to={"/" + defaultId + "/" + accountId + "/"} />
          )}
        />
        <Route
          path='/:id/:accountId'
          render={(props) => (
            <Page active={active} isLoading={isLoading} {...props} />
          )}
        />
      </Switch>
    </div>
  );
}
