import React from "react";

export default function CreateAdminUserQuota({
  quota,
  updateQuota,
  formatedCreditLeft,
  formatedCredit,
  hasUsersQuotaCredit,
  label,
  credit,
}) {
  return (
    <>
      <div
        className='quota-credit'
        style={{ alignItems: "flex-start", flexWrap: "wrap" }}
      >
        <p className='export-margin'> - Exports {label}</p>
        <div className='export-row'>
          <input
            type='number'
            name='quota-siret'
            id='quota-siret'
            className='step-input'
            value={quota}
            onChange={(e) => updateQuota(e.target.value)}
            max={credit}
            min={0}
            placeholder={formatedCreditLeft}
          />
          <div className='export-div'>
            <div className='export-margin'>/ {formatedCredit} disponibles</div>
            {hasUsersQuotaCredit && (
              <i>
                {formatedCreditLeft} disponibles en tenant compte des plafonds
                déjà attribués.
              </i>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
