import React from "react";
import SideBarComponent from "../../shared/components/SideBar/SidebarComponent";

export default function SideBar({
  sideBar,
  accountId,
  closesMobileMenu,
  mobileOpen,
  shownMenu,
  setShownMenu,
}) {
  if (!accountId || !sideBar) {
    return null;
  }
  return (
    <SideBarComponent
      menuItems={sideBar.sideBarMenu}
      accountIdSelected={accountId}
      closesMobileMenu={closesMobileMenu}
      mobileOpen={mobileOpen}
      shownMenu={shownMenu}
      setShownMenu={setShownMenu}
    />
  );
}
