import React, { useState } from "react";
import Modal from "react-responsive-modal";
import Loader, { types } from "../loader/loaderComponent";
import "./CreateFilterModalComponent.scss";

import CreatePreFilterComponent from "./components/CreatePreFilter/CreatePreFilterComponent";
import CreateFilterComponent from "./components/CreateFilter/CreateFilterComponent";

export default function CreateFilterModalComponent({
  accountId,
  reloadItems,
  filterType,
  btntext,
  showAddButton,
  canCreate,
  quotaOk,
}) {
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onOpenModal = () => setOpenModal(true);
  const onCloseModal = () => setOpenModal(false);
  const updateLoader = (e) => setIsLoading(e);

  const getContent = () => {
    switch (filterType) {
      case "filter":
        return (
          <CreateFilterComponent
            updateLoader={updateLoader}
            reloadItems={reloadItems}
            accountId={accountId}
          />
        );
      case "pre-filter":
        return (
          <CreatePreFilterComponent
            updateLoader={updateLoader}
            reloadItems={reloadItems}
            accountId={accountId}
          />
        );
      default:
        return null;
    }
  };

  let addButton = null;
  if (canCreate === undefined) {
    addButton = showAddButton ? (
      <button className='btn btn-primary' onClick={onOpenModal}>
        {btntext}
      </button>
    ) : (
      <button className='btn disabled' onClick={() => {}}>
        {btntext}
      </button>
    );
  } else if (!canCreate) {
    addButton = canCreate && (
      <button className='btn btn-primary' onClick={onOpenModal}>
        {btntext}
      </button>
    );
  } else if (quotaOk) {
    addButton = (
      <button className='btn btn-primary' onClick={onOpenModal}>
        {btntext}
      </button>
    );
  } else {
    addButton = (
      <button className='btn disabled' onClick={() => {}}>
        {btntext}
      </button>
    );
  }

  return !btntext || !filterType ? (
    <></>
  ) : (
    <div className='modal-btn-holder'>
      {addButton}
      <Modal
        classNames={{
          modal: "modal create-filter-modal",
        }}
        open={openModal}
        onClose={onCloseModal}
        focusTrapped={false}
        center
      >
        <div className='single-tab-modal create-filter'>
          <Loader isLoading={isLoading} type={types.MODAL} />
          {getContent()}
        </div>
      </Modal>
    </div>
  );
}
