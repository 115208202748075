import React from "react";
import "./Footer.scss";

export default function Footer({
  item,
  goBack,
  isDisabled,
  goNext,
  handleDownloadExtract,
  alerts,
  forcePotential,
  showNextStep,
  disableUseSolde,
}) {
  const getBackButton = () =>
    goBack && (
      <div className='go-back-link'>
        <button className='btn-as-link' onClick={goBack} type='button'>
          <span className='icon-arrow-thin-left'></span>
          Etape précédente
        </button>
      </div>
    );

  const getNextAction = () => {
    if (!isDisabled) return goNext();
  };

  const getDownloadLink = () => {
    if (!handleDownloadExtract || !item.showExtract) return null;
    return (
      <span className='download-link' onClick={handleDownloadExtract}>
        Voir un extrait
      </span>
    );
  };

  const alertDivs =
    alerts &&
    alerts.map((alert, key) => {
      return (
        <div key={key} className={`alert-footer alert-${alert.type}`}>
          <i className='icon icon-propos' style={{ marginRight: "5px" }} />
          {alert.message}
        </div>
      );
    });

  return (
    item && (
      <footer id='footer' className='footer'>
        {alertDivs}
        <div className='container'>
          <div className='row'>
            {getBackButton()}
            <div className='text-holder'>
              <span className='description'>Potentiel global estimé</span>
              <span className='number'>
                {forcePotential ? forcePotential : item.potential}
              </span>
              <span>entreprises</span>
            </div>
            {getDownloadLink()}
            <div className='btn-holder'>
              <button
                className={
                  !alerts.filter((a) => a.type === "ERROR").length &&
                  showNextStep &&
                  !disableUseSolde
                    ? "btn btn-warning"
                    : "btn disabled"
                }
                onClick={getNextAction}
                type='button'
              >
                {item.nextStepLabel}
                <span className='icon-arrow-thin-right'></span>
              </button>
            </div>
          </div>
        </div>
      </footer>
    )
  );
}
