import React, { useContext } from "react";
import { ErrorContext } from "../../context/ErrorContext";
import "./ErrorBar.scss";

export default function ErrorBar() {
  const [error, setError] = useContext(ErrorContext);

  return (
    <div className={"error-bar " + (error ? "show" : "hidden")}>
      <div className='icon-container'>
        <span className='icon icon-close-lg'></span>
      </div>
      <div className='text-container'>Une erreur est survenue..</div>
      <div className='close-container' onClick={() => setError(false)}>
        <span className='icon icon-close-lg'></span>
      </div>
    </div>
  );
}
