import React from "react";
import "./resetFilter.scss";

export default function ResetFilter({ handleDelete }) {
  return (
    <button onClick={handleDelete} className='reset-btn'>
      <span className='icon icon-refresh'></span>
      <span className='refresh-btn'>Réinitialiser</span>
    </button>
  );
}
