import React, { Component } from "react";
import Switch from "react-switch";
import "./ModalFooterComponent.scss";

class ModalFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: this.props.exclude,
    };

    this.handleChange = this.handleChange.bind(this);
    this.switchOn = this.switchOn.bind(this);
    this.switchOff = this.switchOff.bind(this);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      checked: newProps.exclude,
    });
  }

  handleChange(checked, e, id) {
    this.setState({ checked: checked });
    this.shouldInclude(checked);
  }

  switchOn() {
    if (this.state.checked) {
      this.setState({ checked: false });
      this.shouldInclude(false);
    }
  }

  switchOff() {
    if (!this.state.checked) {
      this.setState({ checked: true });
      this.shouldInclude(true);
    }
  }

  shouldInclude(should) {
    if (this.props.handleChange) {
      this.props.handleChange(should);
    }
  }

  render() {
    if (this.props.option && this.props.option.exclude) {
      return (
        <div
          className={
            "modal-footer" + (this.state.checked ? " exclude" : " include")
          }
        >
          <div className='switch-modal'>
            <div className='switch-label label-on'>
              <span onClick={this.switchOn}>Inclure</span>
            </div>
            <div className='switch'>
              <Switch
                height={12}
                width={32}
                handleDiameter={19}
                uncheckedIcon={false}
                checkedIcon={false}
                onChange={this.handleChange}
                checked={this.state.checked}
                boxShadow={"0 0 2px 3px #dde2eb"}
                activeBoxShadow={""}
                offColor={"#32a31a"}
                onColor={"#f7786b"}
              />
            </div>
            <div className='switch-label label-off'>
              <span onClick={this.switchOff}>Exclure</span>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

export default ModalFooter;
