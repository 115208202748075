import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import "./ListsTable.scss";

import PageLoader from "../pageLoader/pageLoaderComponent";
import SearchHeading from "./SearchHeading";
import Table from "./Table";

export default function ListsTableComponent({
  table,
  pageId,
  accountId,
  editResource,
  deleteModalTitle,
  deleteModalMessage,
  hideSearch,
  sendQueryToApi,
  setOpen,
  setIsTestCrmSuccess,
  setCrmType,
}) {
  const [state, setState] = useState({
    isUpdating: false,
    idHandleSearch: null,
    timer: null,
    table,
  });

  const [activePage, setActivePage] = useState(
    table.options?.tab_page_active || 1
  );

  useEffect(() => {
    // Si les données retournées sont pour la page 1 mais `activePage` est différent de 1
    if (table.options?.tab_page_active === 1 && activePage !== 1) {
      // Forcer la re-pagination vers `activePage`
      refreshTable({ tab_page_active: activePage });
    } else {
      // Sinon, mettre à jour l'état normalement
      if (table.options?.tab_page_active) {
        setActivePage(table.options.tab_page_active);
      }

      setState({
        isUpdating: false,
        idHandleSearch: null,
        timer: null,
        table,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table]);

  const rowsPerPage = state.table.options?.tab_rows_per_page
    ? state.table.options.tab_rows_per_page
    : 8;
  const rowsLength = state.table.options?.tab_rows_count
    ? state.table.options.tab_rows_count
    : state.table?.rowData.length;
  const sortField = state.table.options?.tab_sort_field
    ? state.table.options.tab_sort_field
    : "";
  const sortOrder = state.table.options?.tab_sort_order
    ? state.table.options?.tab_sort_order
    : "desc";
  const searchStr = state.table.options?.tab_search_string
    ? state.table.options.tab_search_string
    : "";

  const refreshTable = (params) => {
    setState((prevState) => ({
      ...prevState,
      isUpdating: true,
    }));
    const newPage = params?.tab_page_active || activePage;
    setActivePage(newPage);
    sendQueryToApi({
      tab_search_string:
        params?.tab_search_string !== undefined
          ? params.tab_search_string
          : searchStr,
      tab_page_active: newPage,
      tab_sort_field: params?.tab_sort_field
        ? params.tab_sort_field
        : sortField,
      tab_sort_order: params?.tab_sort_order
        ? params.tab_sort_order
        : sortOrder,
    });
  };

  return (
    state.table && (
      <div className='table-targeting table-section'>
        <div className='table-holder'>
          <div className='table-wrapper'>
            <SearchHeading
              hideSearch={hideSearch}
              searchStr={searchStr}
              handlerSearch={(e) => {
                clearTimeout(state.timer);
                const timer = setTimeout(() => {
                  refreshTable({
                    tab_search_string: e,
                    tab_page_active: 1,
                  });
                }, 1000);
                setState((prevState) => ({
                  ...prevState,
                  timer,
                }));
              }}
            />
            <Table
              table={state.table}
              sorting={{
                name: sortField,
                sortAsk: sortOrder,
              }}
              searchStr={""}
              rowsPerPage={rowsPerPage}
              activePage={activePage} // Utilisation de l'état `activePage` ici
              accountId={accountId}
              pageId={pageId}
              setUpdating={(value) => {
                setState((prevState) => ({
                  ...prevState,
                  isUpdating: value,
                }));
              }}
              deleteModalMessage={deleteModalMessage}
              editResource={editResource}
              deleteModalTitle={deleteModalTitle}
              handleClickSort={(id) => {
                const order =
                  id === sortField
                    ? sortOrder === "asc"
                      ? "desc"
                      : "asc"
                    : "asc";
                refreshTable({
                  tab_sort_field: id,
                  tab_sort_order: order,
                });
              }}
              refreshTable={refreshTable}
              setOpen={setOpen}
              setIsTestCrmSuccess={setIsTestCrmSuccess}
              setCrmType={setCrmType}
            />
            {state.isUpdating && <PageLoader opacity={true} />}
          </div>
        </div>

        {rowsPerPage < rowsLength && (
          <div className='pagination-holder'>
            <Pagination
              activePage={activePage} // Utilisation de l'état `activePage` ici
              itemsCountPerPage={rowsPerPage}
              totalItemsCount={rowsLength}
              pageRangeDisplayed={5}
              onChange={(pageNumber) => {
                refreshTable({
                  tab_page_active: pageNumber,
                });
              }}
              firstPageText={
                <span className='icon icon-angle-double-left'></span>
              }
              prevPageText={<span className='icon icon-angle-left'></span>}
              nextPageText={<span className='icon icon-angle-right'></span>}
              lastPageText={
                <span className='icon icon-angle-double-right'></span>
              }
            />
          </div>
        )}
      </div>
    )
  );
}
