import React, { useEffect } from "react";
import axios from "axios";
import PageLoader from "../shared/components/pageLoader/pageLoaderComponent";

export default function LogoutNoIframe() {
  useEffect(() => {
    axios.post("/api/logout").then(function (response) {
      window.location.href =
        response.data.logoutUrl +
        "?returnTo=" +
        encodeURIComponent(window.location.origin);
    });
  }, []);

  return (
    <div style={{ marginTop: "200px" }}>
      <PageLoader />
    </div>
  );
}
