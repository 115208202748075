import React, { Component } from "react";

import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";

import "./sliderModalComponent.scss";
import ModalFooter from "../../ModalFooter/ModalFooterComponent";
import { Col, Row } from "react-flexbox-grid";

class SliderView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exclude: false,
      value: null,
      inputValue: null,
      errorMinValue: false,
      errorMaxValue: false,
    };
    this.timerMax = null;
    this.timerMin = null;
    this.handler = this.handler.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handlerMinInput = this.handlerMinInput.bind(this);
    this.handlerMaxInput = this.handlerMaxInput.bind(this);
  }

  componentDidMount() {
    this.setState({
      range: {
        min: this.props.content.option.min,
        max: this.props.content.option.max,
      },
      value: {
        min: this.props.content.option.min,
        max: this.props.content.option.max,
      },
      inputValue: {
        min: this.props.content.option.min,
        max: this.props.content.option.max,
      },
      exclude: this.props.content.option.exclude,
      step: this.props.content.option.step,
    });
  }

  handler(checked) {
    this.setState(
      {
        exclude: checked,
      },
      () => {
        let values = [
          [this.state.value.min.toString(), this.state.value.max.toString()],
        ];

        this.props.updateQuery(
          values,
          this.props.content,
          this.state.exclude,
          this.props.tab,
          this.props.modal
        );
      }
    );
  }

  handleClick() {
    let values = [
      [this.state.value.min.toString(), this.state.value.max.toString()],
    ];

    this.props.updateQuery(
      values,
      this.props.content,
      this.state.exclude,
      this.props.tab,
      this.props.modal
    );
  }

  getSubmit() {
    return (
      <div className='btn-holder'>
        <button
          className={
            "btn btn-primary" +
            (this.state.errorMaxValue === true ||
            this.state.errorMinValue === true
              ? " disable"
              : "")
          }
          onClick={this.handleClick}
        >
          Valider
        </button>
      </div>
    );
  }

  getFooter() {
    return (
      <ModalFooter
        handleChange={this.handler}
        exclude={this.state.exclude}
        option={this.props.content.option}
      />
    );
  }

  handlerMinInput(value, min, max) {
    let val = +value;
    const inputValue = this.state.inputValue;
    let rangeValue = this.state.value;
    let canGo = true;

    if (val < +min || val > +max) {
      canGo = false;
    }

    inputValue.min = val;

    if (canGo) {
      rangeValue.min = val;
    }

    this.setState(
      {
        inputValue,
        errorMinValue: !canGo,
      },
      () => {
        if (this.timerMin) {
          clearTimeout(this.timerMin);
        }

        this.timerMin = setTimeout(() => {
          this.setState({
            value: rangeValue,
          });
        }, 100);
      }
    );
  }

  handlerMaxInput(value, min, max) {
    let val = +value;
    const inputValue = this.state.inputValue;
    let rangeValue = this.state.value;

    let canGo = true;

    if (val < +min || val > +max) {
      canGo = false;
    }

    if (canGo) {
      rangeValue.max = val;
    }
    inputValue.max = val;

    this.setState(
      {
        inputValue,
        errorMaxValue: !canGo,
      },
      () => {
        if (this.timerMax) {
          clearTimeout(this.timerMax);
        }

        this.timerMax = setTimeout(() => {
          this.setState({
            value: rangeValue,
          });
        }, 100);
      }
    );
  }

  handleRange(value) {
    const processedValues = { ...value };

    if (processedValues.min < this.state.range.min) {
      processedValues.min = this.state.range.min;
    }

    if (processedValues.max > this.state.range.max) {
      processedValues.max = this.state.range.max;
    }

    this.setState({ value: processedValues, inputValue: processedValues });
  }

  render() {
    if (!this.props) {
      return null;
    }
    if (!this.state.range) {
      return null;
    }
    return (
      <div className='modal slider-modal'>
        <div
          className={
            "modal-content" + (this.state.exclude ? " exclude" : " include")
          }
        >
          <Row center='xs'>
            <Col xs={12}>
              <InputRange
                maxValue={this.state.range.max}
                minValue={this.state.range.min}
                step={this.state.step}
                value={this.state.value}
                onChange={(value) => this.handleRange(value)}
              />
            </Col>
          </Row>
          <Row center='xs'>
            <Col xs={3}>
              <label
                className={this.state.errorMinValue === true ? "error" : ""}
              >
                <span>De</span>
                <input
                  type='number'
                  step={this.state.step}
                  onChange={(event) =>
                    this.handlerMinInput(
                      event.target.value,
                      +this.state.range.min,
                      +this.state.value.max - 10
                    )
                  }
                  value={this.state.inputValue.min}
                />
              </label>
            </Col>
            <Col xs={3}>
              <label
                className={this.state.errorMaxValue === true ? "error" : ""}
              >
                <span>à</span>
                <input
                  type='number'
                  step={this.state.step}
                  onChange={(event) =>
                    this.handlerMaxInput(
                      event.target.value,
                      +this.state.value.min + 10,
                      +this.state.range.max
                    )
                  }
                  value={this.state.inputValue.max}
                />
              </label>
            </Col>
            <Col xs={2}>{this.getSubmit()}</Col>
          </Row>
        </div>
        {this.getFooter()}
      </div>
    );
  }
}

export default SliderView;
