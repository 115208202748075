import React from "react";
import UserBarComponent from "../../../features/UserBar/UserBarComponent";
import CallSupport from "../CallSupport/callSupport";
import { Link } from "react-router-dom";

export default function ContentHeader({
  accountId,
  component,
  setActiveTab,
  isMobile,
}) {
  const { tabs = [] } = component || {};
  return (
    <div className='PageHeader'>
      <div className='row'>
        {!!tabs.length && (
          <div className='admin-tabs'>
            {tabs
              .filter(({ id }) => {
                return id !== "stats" ? true : !isMobile;
              })
              .map(({ id, label }) => (
                <Link
                  to={`?tabId=${id}`}
                  className={`link-entry ${
                    component.activeTab === id ? "active" : ""
                  }`}
                  key={id}
                  onClick={() => setActiveTab(id)}
                >
                  {label}
                </Link>
              ))}
          </div>
        )}
        <div className='tabs' />
        <CallSupport refCampagne='Web_header_mlist_contact_client' />
        <UserBarComponent accountId={accountId} />
      </div>
    </div>
  );
}
