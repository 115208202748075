import React from "react";
import { Col, Row } from "react-flexbox-grid";
import "./FiltersListComponent.scss";
import CreateFilterModalComponent from "../CreateFilterModal/CreateFilterModalComponent";
import ListsTableComponent from "../ListsTable/ListsTableComponent";

export default function FiltersListComponent({
  accountId,
  data,
  pageId,
  showAddButton,
  headingTitle,
  headingText,
  sendQueryToApi,
  reloadItems,
  filterType,
  btntext,
}) {
  const alertLabel = data.options &&
    data.options.canCreate &&
    !data.options.quotaOk && (
      <p>
        <span className='icon icon-propos' style={{ marginRight: "10px" }} />
        {data.options.infoQuota}
      </p>
    );

  return data ? (
    <div className='scrollAuto'>
      <div className='page-heading'>
        <Row style={{ overflow: "inherit" }}>
          <Col sm={12} md={9}>
            <h2>{headingTitle}</h2>
            <p style={{ overflowWrap: "break-word" }}>{headingText}</p>
            {alertLabel}
          </Col>
          <Col md={3} className='text-right display-btn'>
            <CreateFilterModalComponent
              reloadItems={reloadItems}
              filterType={filterType}
              btntext={btntext}
              accountId={accountId}
              showAddButton={showAddButton}
              canCreate={data.options && data.options.canCreate}
              quotaOk={data.options && data.options.quotaOk}
            />
          </Col>
        </Row>
      </div>
      <ListsTableComponent
        accountId={accountId}
        pageId={pageId}
        table={data}
        sendQueryToApi={sendQueryToApi}
      />
    </div>
  ) : (
    <></>
  );
}
