import React from "react";
import TargetResumeSection from "./components/TargetResumeSection/TargetResumeSectionComponent";
import Footer from "../Footer/FooterComponent";
import CreatingStatus from "./components/CreatingStatus/CreatingStatusComponent";
import { createListConsts } from "../../utils/constants";
import { getItemFromQueryOnQueryFormat } from "./QueryTool";

export default function CreateListParametersPageComponent({
  accountId,
  components,
  requestParams,
  goTo,
  isMobile,
  updateQuery,
  sendQuery,
  handleDownload,
  next,
  back,
}) {
  const getHeader = () => {
    return (
      components.contentHeader && (
        <CreatingStatus
          item={components.contentHeader}
          goTo={goTo}
          accountId={accountId}
        />
      )
    );
  };

  const getFooter = () => {
    const validations = [];
    components.wizardContent.secondaryPanel.packLinkedToCiblage.exportPacks.map(
      (e) =>
        validations.push({
          id: e.id,
          validation: e.validationPattern,
        })
    );
    components.wizardContent.secondaryPanel.packAvailable.exportPacks.map((e) =>
      validations.push({
        id: e.id,
        validation: e.validationPattern,
      })
    );
    const alerts = validations
      .map((v) => {
        const query = getItemFromQueryOnQueryFormat(
          requestParams.packExports,
          v.id
        );
        if (query && query.match(new RegExp(v.validation)) === null) {
          const label =
            components.ExportPackConfigWrapper.exportPackConfigs.find(
              (e) => e.id === `exportPackConfig-${v.id}`
            ).exportPackConfigHeader.primaryLayoutTitle;
          return {
            type: "ERROR",
            message: `Vous devez paramétrer les ${label.toLowerCase()} avant d'exporter`,
          };
        }
        return null;
      })
      .filter((v) => v);

    return (
      components.wizardFooter && (
        <Footer
          alerts={alerts}
          downloadListExtract={true}
          handleDownloadExtract={handleDownload}
          item={components.wizardFooter}
          goNext={() => {
            const validations = [];
            components.wizardContent.secondaryPanel.packLinkedToCiblage.exportPacks.map(
              (e) =>
                validations.push({
                  id: e.id,
                  validation: e.validationPattern,
                })
            );
            components.wizardContent.secondaryPanel.packAvailable.exportPacks.map(
              (e) =>
                validations.push({
                  id: e.id,
                  validation: e.validationPattern,
                })
            );

            if (validations.length > 0) {
              const validationFailed = validations.map((v) => {
                const query = getItemFromQueryOnQueryFormat(
                  requestParams.packExports,
                  v.id
                );
                if (query) {
                  return query.match(new RegExp(v.validation)) !== null;
                }
                return true;
              });
              if (validationFailed.indexOf(false) !== -1) {
                return;
              }
            }
            next();
          }}
          goBack={back}
          showNextStep={components.wizardFooter.showNextStep}
        />
      )
    );
  };

  return (
    accountId &&
    components && (
      <div className='h-100'>
        {getHeader()}
        <TargetResumeSection
          requestParams={requestParams}
          accountId={accountId}
          goToFirstStep={goTo}
          saveTargetingPopin={components.SaveTargetingPopin}
          item={components.wizardContent}
          packConfig={components.ExportPackConfigWrapper}
          sendQuery={(q) => sendQuery(createListConsts.secondStep, q)}
          updateQuery={(vals) => updateQuery(createListConsts.secondStep, vals)}
          isMobile={isMobile}
        />
        {getFooter()}
      </div>
    )
  );
}
