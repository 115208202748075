import React, { createContext, useContext, useState } from "react";

export const ParamsContext = createContext();
export const useParams = () => useContext(ParamsContext);

export const ParamsProvider = ({ children }) => {
  const [params, setParams] = useState({});

  return (
    <ParamsContext.Provider value={{ params, setParams }}>
      {children}
    </ParamsContext.Provider>
  );
};
