import React, { Component } from "react";
import "./ModalSearchFilterCpmponent.scss";

class ModalSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchStr: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.changeSearch = this.changeSearch.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  handleChange(e) {
    e.preventDefault();
    this.props.handleChange(this.state.searchStr);
  }

  changeSearch(e) {
    this.setState(
      {
        searchStr: e.target.value,
      },
      () => {
        this.props.handleChange(this.state.searchStr);
      }
    );
  }

  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.props.handleChange(this.state.searchStr);
    }
  }

  render() {
    return (
      <div className='search-filter'>
        <label className='search-input'>
          <input
            type='text'
            autoFocus={false}
            name='rechercher'
            placeholder='Rechercher...'
            value={this.state.searchStr}
            onChange={this.changeSearch}
            onKeyDown={this.handleKeyDown}
          />
        </label>
      </div>
    );
  }
}

export default ModalSearch;
