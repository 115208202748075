import React from "react";
import HeaderComponent from "../../shared/components/Header/HeaderComponent";

export default function Header({ header, onAccountIdChange, accountId }) {
  return header ? (
    <HeaderComponent
      header={header}
      onAccountIdChange={onAccountIdChange}
      accountId={accountId}
    />
  ) : null;
}
