import React from "react";

export default function DonneesFinanciairesRadioInput({
  disabled,
  id,
  setSelectedGrpDonnees,
  selectedGrpDonnees,
  label,
  p: description,
}) {
  return (
    <div className={`radio-group ${disabled ? "" : "disabled"}`}>
      <input
        type='radio'
        id={id}
        value={id}
        name='radio-group'
        onClick={() => {
          setSelectedGrpDonnees(id);
        }}
        disabled={!disabled}
        defaultChecked={selectedGrpDonnees === id}
      />
      <label htmlFor={id}>{label}</label>
      <p>{description}</p>
    </div>
  );
}
