import React, { useState } from "react";
import { Row, Col } from "react-flexbox-grid";
import "./AccountPage.scss";

import ModifyPasswordModal from "./ModifyPasswordModal/ModifyPasswordModal";
import lock from "../../../assets/images/lock.svg";
import mCompany from "../../../assets/images/m-company.png";
import axios from "axios";

const AccountPageComponent = ({ accountId, components, isMobile, page }) => {
  const [openModal, setOpenModal] = useState(false);
  const [editMod, setEditMod] = useState(false);
  const [state, setState] = useState({
    firstName:
      components.AccountPrimaryPanel.infosPersonnelles.datas["firstName"],
    lastName:
      components.AccountPrimaryPanel.infosPersonnelles.datas["lastName"],
    function:
      components.AccountPrimaryPanel.infosPersonnelles.datas["function"],
    email: components.AccountPrimaryPanel.infosPersonnelles.datas["email"],
  });
  const hideButton = () => {
    setEditMod(true);
  };

  const returnInitial = () => {
    setEditMod(false);
    setState({
      firstName:
        components.AccountPrimaryPanel.infosPersonnelles.datas["firstName"],
      lastName:
        components.AccountPrimaryPanel.infosPersonnelles.datas["lastName"],
      function:
        components.AccountPrimaryPanel.infosPersonnelles.datas["function"],
      email: components.AccountPrimaryPanel.infosPersonnelles.datas["email"],
    });
  };

  const updatePersonalInfo = async () => {
    const FULL_URL = `${process.env.REACT_APP_OIDC_URL}${"update"}`;
    const object = {
      id: page.id,
      email: state.email,
      firstName: state.firstName,
      lastName: state.lastName,
      function: state.function,
    };

    await axios.post(FULL_URL, null, { params: object });
    setEditMod(false);
  };

  const getSideBar = (panel) => {
    if (!panel?.infosPersonnelles) return null;

    const headers = panel.infosPersonnelles.headers;
    const datas = panel.infosPersonnelles.datas;
    return (
      <div className='account-sidebar'>
        <h2>
          Mes infos personnelles
          {!editMod && (
            <button className='edit' onClick={hideButton}>
              <span className='icon icon-edit' />
            </button>
          )}
        </h2>
        <ul className='list-info'>
          {Object.keys(headers).map((header) => (
            <li key={header} className='liste'>
              <span
                className={`label ${header === "password" ? "hidden" : ""} `}
              >
                {headers[header]}
              </span>

              <span className='info'>
                {(!editMod || headers[header] === "Email") &&
                  header !== "password" &&
                  state[header]}
                {!editMod &&
                  header === "function" &&
                  state[header] === "" &&
                  " - "}
                {(header === "firstName" || header === "lastName") &&
                  editMod &&
                  datas["isGoogleAuth"] === "true" &&
                  state[header]}

                {editMod &&
                  datas["isGoogleAuth"] === "false" &&
                  header !== "email" &&
                  header !== "password" &&
                  header !== "function" && (
                    <input
                      type='text'
                      name='edit-filter'
                      id='edit-filter'
                      className='edit-input'
                      maxLength={255}
                      value={state[header]}
                      onChange={(e) => {
                        if (header === "firstName") {
                          setState({
                            ...state,
                            firstName: e.target.value,
                          });
                        } else {
                          setState({
                            ...state,
                            lastName: e.target.value,
                          });
                        }
                      }}
                    />
                  )}
                {editMod &&
                  header === "function" &&
                  header !== "email" &&
                  header !== "password" && (
                    <input
                      type='text'
                      name='edit-filter'
                      id='edit-filter'
                      className='edit-input'
                      maxLength={255}
                      value={state[header]}
                      onChange={(e) => {
                        setState({
                          ...state,
                          function: e.target.value,
                        });
                      }}
                    />
                  )}
                {header === "password" && (
                  <span className='modify'>
                    <button onClick={() => setOpenModal(true)}>
                      {" "}
                      <img src={lock} alt='lock' className='lock' />{" "}
                      Réinitialiser le mot de passe{" "}
                    </button>
                  </span>
                )}
              </span>
            </li>
          ))}
        </ul>
        {editMod && (
          <div className='actions-holder'>
            <button className='btn btn-primary' onClick={updatePersonalInfo}>
              Enregistrer
            </button>
            <button className='btn btn-secondary' onClick={returnInitial}>
              Annuler
            </button>
          </div>
        )}
      </div>
    );
  };

  const getContent = () => {
    return (
      <div className='detail-info'>
        <header className='text-holder'>
          <div>
            <h2>Votre contact chez Manageo</h2>
            <p>Une question ? Nous restons à votre disposition.</p>
          </div>
        </header>
        {getContactInfo()}
        <hr className='mb50' />
        <header className='text-holder'>
          <div>
            <h2>A découvrir aussi</h2>
          </div>
        </header>
        {getCompanyList()}
      </div>
    );
  };

  function getContactInfo() {
    return (
      <div className='contact-info-block'>
        <address>
          <h4>Service client</h4>
          <a
            className='contact-info-block-mail'
            href='mailto:contact.aix@altares.com'
          >
            contact.aix@altares.com
          </a>
          <a className='contact-info-block-telephone' href='tel:0442903410'>
            04 42 90 34 10
          </a>
        </address>
      </div>
    );
  }

  function getCompanyList() {
    return (
      <div className='companies-list'>
        <div className='company-item'>
          <p>
            Manageo met à votre disposition de nombreuse solutions dédiées à
            votre développement commercial.
          </p>
        </div>
        <div className='company-item'>
          <img src={mCompany} alt='' />
          <p>
            Accédez à toute l'information légale & financière sur + de 10
            millions d'entreprises françaises.
          </p>
          <a
            href='https://www.manageo.fr/savoir.html'
            target='_blank'
            rel='noopener noreferrer'
            className='link'
          >
            <span className='icon icon-plus' />
            En savoir plus
          </a>
        </div>
      </div>
    );
  }

  if (!components) return null;

  return (
    <>
      <ModifyPasswordModal
        closeModal={() => setOpenModal(false)}
        openModal={openModal}
      />
      <Row className='PageContent h-100  scrollAuto'>
        <Col
          sm={12}
          md={4}
          className='h-100 with-shadow with-colorful-bg scrollAuto'
        >
          <>{getSideBar(components.AccountPrimaryPanel)}</>
        </Col>
        <Col sm={12} md={8} className='h-100 scrollAuto'>
          <>{getContent(components.AccountSecondaryPanel)}</>
        </Col>
      </Row>
    </>
  );
};

export default AccountPageComponent;
