import React, { Component } from "react";
import "./PotentialSectionComponent.scss";

import { Fade } from "react-slideshow-image";

const fadeProperties = {
  transitionDuration: 100,
  autoplay: false,
  infinite: false,
  indicators: false,
  onChange: (oldIndex, newIndex) => {},
};

export default class PotentialSectionComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    // this.props.getFilters();
  }

  separateArray(arr, chunk_size) {
    const array = arr;
    const arrayLength = array.length;
    const newarr = [];

    for (let index = 0; index < arrayLength; index += chunk_size) {
      const myChunk = array.slice(index, index + chunk_size);
      newarr.push(myChunk);
    }

    return newarr;
  }

  getListItems(el) {
    return el.map((e, i) => {
      return (
        <li key={i} className='slide-list-item'>
          <span className='item-holder'>
            <span
              className={"icon icon-" + (e.icon ? e.icon : "default")}
            ></span>
            <span className='text'>
              <span className='title'>{e.name}</span>
              <span className='description'>{e.description}</span>
            </span>
          </span>
        </li>
      );
    });
  }

  getList(el) {
    return <ul className='slide-list'>{this.getListItems(el)}</ul>;
  }

  getSlide(slides) {
    const newSlides = this.separateArray(slides, 4);
    return newSlides.map((e, i) => {
      return (
        <div key={i} className='each-slide'>
          {this.getList(e)}
        </div>
      );
    });
  }

  getSlider = (content) => {
    return <Fade {...fadeProperties}>{this.getSlide(content.packs)}</Fade>;
  };

  render() {
    if (!this.props || !this.props.content) {
      return null;
    }
    return (
      <div className='potential-section slider-holder'>
        <h3>{this.props.content.title}</h3>
        {this.getSlider(this.props.content)}
      </div>
    );
  }
}
