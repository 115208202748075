import React, { useState } from "react";
import axios from "axios";

import PopUpModal from "../../../PopUpModal/PopUpModalComponent";
import RadioButton from "../../../../fields/RadioButton/RadioButton";

export default function TargetResumePrimaryPanel({
  panel,
  saveTargetingPopin,
  accountId,
  query,
  queryCondition,
  goToFirstStep,
}) {
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({
    open: false,
    name: "",
    comment: "",
    segmentation: saveTargetingPopin.segmentation ? null : false,
    isLoading: false,
  });

  const closeModal = () => {
    setOpen(false);
    setState({
      ...state,
      name: "",
      comment: "",
      segmentation: saveTargetingPopin.segmentation ? null : false,
      isLoading: false,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setState({
      ...state,
      isLoading: true,
    });

    const FULL_URL = `/api/mlist/web/targetings`;
    axios
      .post(FULL_URL, {
        name: state.name,
        comment: state.comment,
        segmentation: state.segmentation,
        accountId: accountId,
        query,
        queryCondition,
      })
      .then(() => setOpen(false));
  };

  if (!panel || !panel.recapTargeting) return null;

  return (
    <div className='selected-list-section'>
      <div className='list-top-section'>
        <header className='text-holder'>
          <h2>{panel.title.title}</h2>
          <h3>{panel.title.subTitle}</h3>
        </header>
        <ul className='btn-controller'>
          <li>
            <span
              className='fake-link'
              onClick={() => goToFirstStep("step-ciblage")}
            >
              <span className='icon icon-edit' />
              <span className='btn-title'>Modifier</span>
            </span>
          </li>
          {saveTargetingPopin && saveTargetingPopin.authorized && (
            <li>
              <span className='fake-link' onClick={() => setOpen(true)}>
                <span className='icon icon-on-load' />
                <span className='btn-title'>Sauvegarder</span>
              </span>
              <PopUpModal
                open={open}
                closeModal={closeModal}
                title='Sauvegarde de votre ciblage'
                description='Complétez les informations ci-dessous pour enregistrer votre ciblage. Vous le retrouverez dans vos filtres et dans la rubrique personnalisation.'
                content={
                  <form onSubmit={handleSubmit}>
                    <div className='input-row'>
                      <label htmlFor='name'>
                        <span>Nommez votre ciblage</span>
                      </label>
                      <input
                        className='input'
                        id='name'
                        type='text'
                        name='name'
                        value={state.name}
                        onChange={(event) =>
                          setState({
                            ...state,
                            name: event.target.value,
                          })
                        }
                        required='required'
                      />
                    </div>
                    <div className='input-row mb-2x'>
                      <label htmlFor='comment'>
                        <span>Ajoutez un commentaire</span>
                      </label>
                      <input
                        className='input'
                        id='comment'
                        type='text'
                        name='comment'
                        value={state.comment}
                        onChange={(event) =>
                          setState({
                            ...state,
                            comment: event.target.value,
                          })
                        }
                      />
                    </div>
                    {saveTargetingPopin.segmentation && (
                      <div className='input-row'>
                        <label htmlFor='segmentation'>
                          <span>
                            Votre ciblage est-il destiné à servir de
                            segmentation par défaut à un ou plusieurs
                            utilisateurs ?
                          </span>
                        </label>
                        <div className='radio-row'>
                          <RadioButton
                            checked={state.segmentation === true}
                            label={"Oui"}
                            onClick={() =>
                              setState({
                                ...state,
                                segmentation: true,
                              })
                            }
                          />
                          <RadioButton
                            checked={state.segmentation === false}
                            label={"Non"}
                            onClick={() =>
                              setState({
                                ...state,
                                segmentation: false,
                              })
                            }
                          />
                        </div>
                      </div>
                    )}
                    <div className='btn-row'>
                      <input
                        className={
                          "btn btn-primary " +
                          (!state.name || state.segmentation === null
                            ? "disabled"
                            : "")
                        }
                        type='submit'
                        value='Valider'
                      />
                      <span onClick={closeModal} className='btn btn-secondary'>
                        Annuler
                      </span>
                    </div>
                  </form>
                }
              />
            </li>
          )}
        </ul>
      </div>
      <ul className='primary-list'>
        {!!panel.recapTargeting.recapFilterFamilies.length &&
          panel.recapTargeting.recapFilterFamilies.map((el, i) => {
            return (
              <li key={i}>
                <span className='list-heading'>{el.labelFamily}</span>
                {!!el.recapFilterData.length && (
                  <ul className='under-list'>
                    {el.recapFilterData.map((el, i) => (
                      <li key={i}>
                        <span
                          className={
                            "icon icon-" + (!!el.exclu ? "close" : "confirm")
                          }
                        />
                        <span className='title'>{el.label}</span>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            );
          })}
      </ul>
    </div>
  );
}
