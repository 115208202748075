import React, { useState } from "react";
import "./callSupport.scss";
import ContactModal from "../ContactModal/ContactModalComponent";

export default function CallSupport({ refCampagne }) {
  const [contactModalOpen, setContactModalOpen] = useState(false);

  const toggleContactModal = () => setContactModalOpen(!contactModalOpen);

  return (
    <div className='callSupport'>
      <span
        className='icon icon-support-call'
        onClick={toggleContactModal}
      ></span>
      <ContactModal
        open={contactModalOpen}
        onClose={toggleContactModal}
        refCampagne={refCampagne}
      />
    </div>
  );
}
