import React from "react";
import { Col } from "react-flexbox-grid";
import ColumnChartComponent from "../CreateList/components/ColumnChart/ColumnChartComponent";
import Dropdown from "../Dropdown/Dropdown";

export default function KeyValue({ keyValues }) {
  const {
    lastCa,
    lastRe,
    lastCp,
    lastDi,
    lastCda,
    lastEg,
    lastYear,
    chiffreAffaires,
    resultatExploitation,
    capitauxPropres,
    disponibilites,
    capaciteAutoFinancement,
    endettementGlobal,
  } = keyValues;

  return (
    <Dropdown
      title={<h2>Chiffres clés &amp; évolutions</h2>}
      content={
        <>
          {
            <h3 className='leftText'>
              {lastYear
                ? `Date du dernier bilan connu : ${lastYear}`
                : "Cette entreprise ne publie pas son bilan ou a décidé de le garder confidentiel"}
            </h3>
          }
          <div className='row key-values'>
            {lastCa && (
              <div className='col card'>
                <p>Chiffres d'affaires</p>
                <span>{`${lastCa} € `}</span>
                en {lastYear}
              </div>
            )}
            {lastRe && (
              <div className='col card'>
                <p>Résultat d'exploitation</p>
                <span>{`${lastRe} € `}</span>
                en {lastYear}
              </div>
            )}
            {lastCp && (
              <div className='col card'>
                <p>Capitaux propres</p>
                <span>{`${lastCp} € `}</span>
                en {lastYear}
              </div>
            )}
            {lastDi && (
              <div className='col card'>
                <p>Disponibilité</p>
                <span>{`${lastDi} € `}</span>
                en {lastYear}
              </div>
            )}
            {lastCda && (
              <div className='col card'>
                <p>Capacité d'autofinancement</p>
                <span>{`${lastCda} € `}</span>
                en {lastYear}
              </div>
            )}
            {lastEg && (
              <div className='col card'>
                <p>Endettement global</p>
                <span>{`${lastEg} € `}</span>
                en {lastYear}
              </div>
            )}
          </div>
          <div className='row visual-graphs'>
            <div className='col'>
              {chiffreAffaires && (
                <Col>
                  <ColumnChartComponent item={chiffreAffaires} />
                </Col>
              )}
            </div>
            <div className='col'>
              {resultatExploitation && (
                <Col>
                  <ColumnChartComponent item={resultatExploitation} />
                </Col>
              )}
            </div>
            <div className='col'>
              {capitauxPropres && (
                <Col>
                  <ColumnChartComponent item={capitauxPropres} />
                </Col>
              )}
            </div>
            <div className='col'>
              {disponibilites && (
                <Col>
                  <ColumnChartComponent item={disponibilites} />
                </Col>
              )}
            </div>
            <div className='col'>
              {capaciteAutoFinancement && (
                <Col>
                  <ColumnChartComponent item={capaciteAutoFinancement} />
                </Col>
              )}
            </div>
            <div className='col'>
              {endettementGlobal && (
                <Col>
                  <ColumnChartComponent item={endettementGlobal} />
                </Col>
              )}
            </div>
          </div>
        </>
      }
    />
  );
}
