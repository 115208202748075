import React from "react";

export default function TableHeading({ headers, sorting, handleClickSort }) {
  return headers.map((e, i) => {
    let className = "sort-item ";
    className += i !== 0 && i !== headers.length - 1 ? "display-mobile " : "";
    className += sorting.name === e.id ? `${sorting.sortAsk} ` : "";
    className += !e.sortable ? "not-sortable" : "";
    return (
      <th
        key={i}
        id={e.id}
        onClick={() => handleClickSort(e.id, e.sortable)}
        className={className}
      >
        {e.label}
        <span
          className={
            "icon icon-chevron-down " + (sorting.name === e.id ? "visible" : "")
          }
        />
      </th>
    );
  });
}
