import React, { useEffect, useState } from "react";
import { HorizontalBar, defaults } from "react-chartjs-2";

defaults.global.defaultFontFamily = "Raleway";

const buildLabels = (chart) => {
  let labelsList = [];
  chart.values.forEach((b) => {
    labelsList.push(b.nameValue[0]);
  });
  return labelsList;
};
const buildDatas = (chart) => {
  let datalist = [];
  chart.values.forEach((b) => {
    datalist.push(b.value);
  });
  return datalist;
};

const buildTitles = (chart) => {
  let titleList = [];
  chart.values.forEach((b) => {
    titleList.push(b.nameValue[0]);
  });
  return titleList;
};

const HorizontalColumnChartComponent = ({ item }) => {
  const [state, setState] = useState({
    labels: buildLabels(item),
    titles: buildTitles(item),
    dataSetO: buildDatas(item),
  });

  const data = {
    labels: state.labels,
    titles: state.titles,
    datasets: [
      {
        data: state.dataSetO,
        hoverBorderColor: "#e6e6e6",
        backgroundColor: [
          "#ffb400",
          "#4e5c7b",
          "#a9524e",
          "#c8d4e8",
          "#91a8d0",
          "#fbe337",
          "#AF33FF",
          "#FF33A5",
          "#8dd674",
          "#f7776b",
        ],
      },
    ],
    formattedToolTips: [],
  };

  const options = {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: item.title,
      fontColor: "#4a63a1",
      fontSize: "14",
      fontStyle: "bold",
    },
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
            precision: 0,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            autoSkip: false,
          },
        },
      ],
    },
    tooltips: {
      labels: false,
      displayColors: false,
    },
  };

  useEffect(() => {
    setState({
      labels: buildLabels(item),
      titles: buildTitles(item),
      dataSetO: buildDatas(item),
    });
  }, [item]);

  return (
    <div className='with-bg'>
      <HorizontalBar options={options} data={data} height={145} />
    </div>
  );
};
export default HorizontalColumnChartComponent;
