import React, { Component } from "react";

import "./SwitchModalComponent.scss";
import Slider from "react-rangeslider";

class SwitchModalComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      values: this.props.content.concepts[0].values,
      value: 2,
    };
  }

  componentDidMount() {
    this.props.concept.forEach((concept) => {
      if (concept.filters && concept.filters.length) {
        concept.filters
          .filter(
            (filter) =>
              this.props.content.concepts[0].conceptName === filter.conceptRef
          )
          .forEach((filter) => {
            let selectedValue = 2;
            this.state.values.forEach((value, key) => {
              if (value.value === filter.expressions[0]) {
                if (key === 0) {
                  selectedValue = 1;
                } else if (key === 1) {
                  selectedValue = 3;
                }
              }
            });
            this.setState({
              value: selectedValue,
            });
          });
      }
    });
  }

  selectPreFilter = (value) => {
    if (value !== this.state.value) {
      let arrayToSend = [];
      if (value === 1) {
        arrayToSend.push({
          value: this.props.content.concepts[0].values[0].value,
        });
      } else if (value === 3) {
        arrayToSend.push({
          value: this.props.content.concepts[0].values[1].value,
        });
      }

      this.setState(
        {
          value,
        },
        () => {
          this.props.updateQuery(
            arrayToSend,
            this.props.content,
            0,
            this.props.tab,
            this.props.modal
          );
        }
      );
    }
  };

  getSliderClass() {
    let classText = "";

    if (this.state.value === 1 || this.state.value === 3) {
      classText = "active";
    }

    return classText;
  }

  render() {
    return (
      <div className='modal modal-switch'>
        <div
          className={
            "modal-content" + (this.state.exclude ? " exclude" : " include")
          }
        >
          <div
            className={
              "prefilter-slider slider-container " + this.getSliderClass()
            }
          >
            <span>{this.state.values[0].label}</span>
            <Slider
              min={1}
              max={3}
              orientation='horizontal'
              value={this.state.value}
              onChange={(val) => this.selectPreFilter(val)}
            />
            <span>{this.state.values[1].label}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default SwitchModalComponent;
