import React from "react";
import FiltersListComponent from "../FiltersList/FiltersListComponent";

export default function MyFiltersComponent({
  components,
  pageId,
  isMobile,
  reloadPage,
  accountId,
  sendQueryToApi,
}) {
  return (
    components && (
      <FiltersListComponent
        filterType={"filter"}
        headingTitle={"Mes filtres"}
        headingText={`Accédez à la liste complète de vos filtres ainsi qu'au processus de création de nouveaux filtres`}
        btntext={"Créer un filtre"}
        pageId={pageId}
        reloadItems={reloadPage}
        data={components.mesFiltresTable}
        accountId={accountId}
        isMobile={isMobile}
        showAddButton={components.mesFiltresTable.showAddButton}
        sendQueryToApi={sendQueryToApi}
      />
    )
  );
}
