import React from "react";
import Collapsible from "react-collapsible";
import "./AnimatedDropdown.scss";

export default function AnimatedDropdown({ title, content, isClose }) {
  return (
    <div className='dropdown'>
      <Collapsible
        open={!isClose}
        trigger={<div className='title'>{title}</div>}
      >
        <div className='content'>{content}</div>
      </Collapsible>
    </div>
  );
}
