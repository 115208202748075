import React, { useState, useEffect } from "react";
import "./CreatingStatus.scss";
import UserBarComponent from "../../../../../features/UserBar/UserBarComponent";
import CallSupport from "../../../CallSupport/callSupport";

const CreatingStatus = ({ item, accountId, goTo }) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  useEffect(() => {
    getPassedClass();
  });

  const goToStep = (step) => {
    goTo(step, null, true);
  };

  const renderStepItems = () => {
    if (!item.stepWizard.steps) {
      return null;
    }
    return item.stepWizard.steps.map((stepItem, i) => {
      return (
        <li
          key={i}
          id={stepItem.id}
          className={
            "stepItem" +
            (stepItem.active ? " active" : "") +
            (i < currentStepIndex ? " passed" : "") +
            (i > currentStepIndex ? " disabled" : "")
          }
        >
          <span className='fake-link' onClick={() => goToStep(stepItem.id)}>
            <span className={"icon icon-" + stepItem.id}></span>
            <span className='step-title'>
              {stepItem.title}
              <span className='circle-step'></span>
            </span>
          </span>
        </li>
      );
    });
  };

  const getPassedClass = () => {
    item.stepWizard.steps.forEach((element, i) => {
      if (element.active === true) {
        setCurrentStepIndex(i);
      }
    });
  };

  if (!item) {
    return null;
  }
  return (
    <div className='PageHeader'>
      <div className='row'>
        <div className='step-col'>
          <ul className='steps'>
            {item.stepWizard.steps ? renderStepItems() : ""}
          </ul>
        </div>
        <CallSupport refCampagne='Web_header_mlist_contact_client' />
        <UserBarComponent accountId={accountId} />
      </div>
    </div>
  );
};

export default CreatingStatus;
