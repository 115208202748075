import React from "react";

import UserBarComponent from "../../../features/UserBar/UserBarComponent";
import CallSupport from "../CallSupport/callSupport";

export default function EstablissementHeader({ accountId, closePage }) {
  return (
    <div className='PageHeader'>
      <div className='row'>
        <div className='return-button-wrapper'>
          <span
            onClick={closePage}
            className='icon icon-arrow-thin-right return-arrow return-button'
          ></span>
          <p onClick={closePage} className='return-button'>
            Retour à la liste
          </p>
        </div>
        <CallSupport refCampagne='Web_header_mlist_contact_client' />
        <UserBarComponent accountId={accountId} />
      </div>
    </div>
  );
}
