// Constants for each page

export const createListConsts = {
  defaultPage: "/createList/step-ciblage",
  firstStep: "step-ciblage",
  firstStepUrl: "/createList/step-ciblage",
  secondStep: "step-parametrage",
  secondStepUrl: "/createList/step-parametrage",
  thirdStep: "step-exportation",
  thirdStepUrl: "/createList/step-exportation",
  fourthStep: "step-confirmation",
  fourthStepUrl: "/createList/step-confirmation",
  mapPositionDef: {
    latTopLeft: 51.12421275782688,
    lonTopLeft: -9.096679687500002,
    latBottomRight: 42.87596410238256,
    lonBottomRight: 13.798828125000002,
  },
};
