import React from "react";
import { Col, Row } from "react-flexbox-grid";

import FilterSection from "./components/FilterSection/FilterSectionComponent";
import CreatingStatus from "./components/CreatingStatus/CreatingStatusComponent";
import Footer from "../Footer/FooterComponent";
import EstablishedSectionComponent from "./components/EstablishedSection/EstablishedSectionComponent";
import Establissement from "../Establissement/Establissement";

export default function CreateListTargetingComponent({
  sendQuery,
  components,
  goTo,
  accountId,
  next,
  back,
  children,
  isModalLoading,
  currentQuery,
  pageIsLoading,
  useSavedTargeting,
  isMobile,
}) {
  const getHeader = () =>
    components.contentHeader && (
      <CreatingStatus
        item={components.contentHeader}
        goTo={goTo}
        accountId={accountId}
      />
    );

  const getFooter = () =>
    components.wizardFooter && (
      <Footer
        item={components.wizardFooter}
        goNext={next}
        goBack={back}
        showNextStep={components.wizardFooter.showNextStep}
        alerts={components.wizardFooter.alerts}
      />
    );

  const getPage = () =>
    components.Establishment ? (
      <Establissement
        accountId={accountId}
        isMobile={isMobile}
        establishment={components.Establishment}
        sendQuery={sendQuery}
        goNext={next}
      />
    ) : (
      <div className='h-100'>
        {getHeader()}
        <Row className='PageContent h-100'>
          <Col sm={12} md={4} className='h-100 pb-10 with-shadow'>
            {children}
            <FilterSection
              isModalLoading={isModalLoading}
              currentQuery={currentQuery}
              pageIsLoading={pageIsLoading}
              sendQuery={sendQuery}
              useSavedTargeting={useSavedTargeting}
              components={components}
              accountId={accountId}
              isMobile={isMobile}
            />
          </Col>
          <Col sm={12} md={8} className='h-100 pb-10 scrollAuto'>
            <EstablishedSectionComponent
              sendQuery={sendQuery}
              components={components}
              cards={components.establishmentCard}
              content={components.wizardContent.secondaryPanel}
              mapContent={components.wizardStep1MapContent}
              charts={components.wizardStep1MapContent}
              currentQuery={currentQuery}
              ds={accountId}
              isMobile={isMobile}
            />
          </Col>
        </Row>
        {getFooter()}
      </div>
    );

  return components && getPage();
}
