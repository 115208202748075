import React from "react";
import "./RadioButton.scss";

export default function RadioButton({ checked, label, onClick }) {
  return (
    <label className='radio-button' htmlFor={label} onClick={onClick}>
      <div className={"custom-radio " + (checked ? "checked" : "")}>
        <input type='radio' checked={checked} onChange={() => {}} />
      </div>
      {label}
    </label>
  );
}
