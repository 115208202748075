import "./Admin.scss";
import React, { useState } from "react";
import AdminUser from "./User/AdminUser";
import ContentHeader from "../ContentHeader/ContentHeader";
import AdminSegmentation from "./Segmentation/AdminSegmentation";
import AdminConnector from "./Connector/AdminConnector";
import AdminSettings from "./AdminSettings";
import AdministrationStats from "./Stats/AdministrationStats";
import PageLoader from "../pageLoader/pageLoaderComponent";
import AdministrationDashboard from "./dashboard/AdministrationDashboard";

export default function Admin({
  accountId,
  pageId,
  components,
  sendQueryToApi,
  isMobile,
}) {
  const [isUpdating, setIsUpdating] = useState(false);

  return (
    <>
      {isUpdating && <PageLoader opacity={true} />}
      {Object.values(components).map((c, key) =>
        getComponentByType(c, accountId, sendQueryToApi, pageId, key)
      )}
    </>
  );

  function getComponentByType(
    component,
    accountId,
    sendQueryToApi,
    pageId,
    key
  ) {
    switch (component.type) {
      case "TabContentHeader":
        return (
          <ContentHeader
            accountId={accountId}
            component={component}
            key={key}
            setActiveTab={(activeTab) => {
              setIsUpdating(true);
              sendQueryToApi(
                {
                  tabId: activeTab,
                },
                () => setIsUpdating(false)
              );
            }}
            isMobile={isMobile}
          />
        );
      case "UserListTable":
        return (
          <AdminUser
            pageId={pageId}
            key={key}
            accountId={accountId}
            component={component}
            sendQueryToApi={sendQueryToApi}
          />
        );
      case "SegmentationListTable":
        return (
          <AdminSegmentation
            pageId={pageId}
            key={key}
            accountId={accountId}
            component={component}
            sendQueryToApi={sendQueryToApi}
          />
        );
      case "AdministrationStats":
        return (
          <AdministrationStats
            pageId={pageId}
            accountId={accountId}
            key={key}
            component={component}
            sendQueryToApi={sendQueryToApi}
          />
        );
      case "ConnectorListTable":
        return (
          <AdminConnector
            pageId={pageId}
            key={key}
            accountId={accountId}
            component={component}
            sendQueryToApi={sendQueryToApi}
          />
        );
      case "AdministrationSettings": {
        return <AdminSettings key={key} component={component} />;
      }
      case "AdministrationDashboard": {
        return (
          <AdministrationDashboard
            accountId={accountId}
            key={key}
            component={component}
            sendQueryToApi={sendQueryToApi}
          />
        );
      }
      default:
        console.error(`the component : ${component.type} is unknown`);
    }
  }
}
