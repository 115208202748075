import React, { useState, useEffect } from "react";
import "./ExportSection.scss";
import { Col, Row } from "react-flexbox-grid";
import { createListConsts } from "../../../../utils/constants";
import "../../../ContactModal/ContactModalComponent";
import ContactModal from "../../../ContactModal/ContactModalComponent";

export default function ExportSection({
  item,
  isMobile,
  updateQuery,
  useSolde,
  setUseSolde,
  fileFormat,
  displayUseSolde,
  setDisplayUseSolde,
}) {
  const [popContactOpen, setPopContactOpen] = useState(false);
  const [formats, setFormats] = useState([]);

  useEffect(() => {
    if (item && item.secondaryPanel && item.secondaryPanel.exportFormats) {
      setFormats(item.secondaryPanel.exportFormats);
    }
    setUseSolde(false);
  }, [item, setUseSolde]);

  const getExportCreditInfo = (info) =>
    info && (
      <div className='card-holder'>
        <div className='card-header'>
          <div className='icon-holder'>
            <span className={`icon ${info.icon}`}></span>
          </div>
          <h2 className='card-title'>{info.title}</h2>
          <p className='card-title-number'>
            {useSolde ? info.balance : info.credit}
          </p>
        </div>
        {info.displayBalance && (
          <div className='card-content'>
            <h3 className='card-content-title'>{info.creditTitle}</h3>
            <p
              className={
                `card-content-title-number ` +
                (Number(info.balance.replace(/\s+/g, "")) <
                Number(info.credit.replace(/\s+/g, ""))
                  ? "red"
                  : "")
              }
            >
              {info.balance}
            </p>
          </div>
        )}
        {info.displayBalance &&
          Number(info.balance.replace(/\s+/g, "")) <
            Number(info.credit.replace(/\s+/g, "")) && (
            <div
              className={
                `card-hint ` + (info.infoType === "DANGER" ? "danger" : "")
              }
            >
              <br />
              <p>
                <span className={`icon ${info.iconInfo}`}></span>
                {info.info}
              </p>
            </div>
          )}
        <br />
        {info.title === `Nombre de crédits emails fonctionnels nécessaires` &&
          item.primaryPanel.creditSiretLowerBalanceSiret && (
            <div
              className={
                `card-hint ` + (info.infoType === "DANGER" ? "danger" : "")
              }
            >
              <p>
                <span className={`icon ${info.iconInfo}`}></span>
                Le chiffrage des crédits nécessaires est une estimation. Votre
                solde définitif sera calculé après génération de la liste. En
                cas d'écart en votre défaveur, votre compte sera recrédité de la
                différence entre cette estimation et le volume réel consommé.
              </p>
            </div>
          )}
      </div>
    );

  const getExportCreditInfos = (creditInfos) => {
    return creditInfos.map((e, i) => {
      return (
        <Col key={i} md={12 / creditInfos.length} xs={12}>
          {getExportCreditInfo(e)}
        </Col>
      );
    });
  };

  const getPrimaryPanel = (primaryPanel) =>
    primaryPanel && (
      <div className='export-credits-section'>
        <header className='text-holder'>
          <h2>{primaryPanel.title.title}</h2>
          <h3>{primaryPanel.title.subTitle}</h3>
        </header>

        <div className='export-credits-content'>
          <Row>{getExportCreditInfos(primaryPanel.exportCreditInfos)}</Row>
        </div>
        <br />
        <div className='inner_div'>
          {primaryPanel.displayRachatButton && (
            <button
              className='btn btn-secondary btn-modal'
              onClick={() => setPopContactOpen(true)}
            >
              {primaryPanel.labelRachatButton}
            </button>
          )}
          {primaryPanel.displayUseSoldeButton && (
            <button
              className='btn btn-primary btn-modal'
              onClick={() => setDisplayUseSolde(false)}
            >
              {primaryPanel.labelUseButton}
            </button>
          )}
        </div>
        <div></div>
      </div>
    );

  const selectFileType = (id) => {
    updateQuery(createListConsts.thirdStep, id);
  };

  const getExportFormats = () =>
    formats &&
    formats.map((e, i) => {
      return (
        <div
          key={i}
          className='export-format-col'
          onClick={e.selectable ? () => selectFileType(e.id) : null}
        >
          <div
            className={`export-format ${
              e.selectable && !displayUseSolde ? "" : "unselectable"
            } ${fileFormat === e.id ? " selected" : ""}`}
          >
            <div className='icon-block'>
              <span
                className={`icon ${
                  !e.icon ? "icon-" + e.id : "icon-" + e.icon
                }`}
              ></span>
            </div>
            <span className='export-format-label'>{e.label}</span>
          </div>
        </div>
      );
    });

  const getSecondaryPanel = (secondaryPanel) =>
    secondaryPanel && (
      <div className='export-format-section'>
        <header className='text-holder'>
          <h1>{secondaryPanel.title.title}</h1>
          <h3>{secondaryPanel.title.subTitle}</h3>
        </header>
        <div className='export-format-holder'>
          {getExportFormats(secondaryPanel.exportFormats)}
        </div>
      </div>
    );

  return (
    item && (
      <Row className='PageContent h-100'>
        <Col sm={12} md={5} className='h-100 with-shadow pb-10 scrollAuto'>
          <>{getPrimaryPanel(item.primaryPanel)}</>
        </Col>
        <Col sm={12} md={7} className='h-100 pb-10 scrollAuto'>
          <>{getSecondaryPanel(item.secondaryPanel)}</>
        </Col>
        <ContactModal
          open={popContactOpen}
          refCampagne='web_mlist_dde_credit'
          onClose={() => {
            setPopContactOpen(false);
          }}
        />
      </Row>
    )
  );
}
