import React from "react";
import Dropdown from "../Dropdown/Dropdown";

export default function GeneralInformation({ generalInformation }) {
  const {
    rs,
    commercialName,
    siren,
    siret,
    duns,
    tva,
    nature,
    created,
    activity,
    legalStatus,
    capital,
    globalTranche,
    effectifTranche,
  } = generalInformation;

  const numberWithSpaces = (number) =>
    number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  return (
    rs && (
      <Dropdown
        title={<h2>Informations générales</h2>}
        content={
          <div className='general-information'>
            <div className='row'>
              <div className='col'>Raison sociale</div>
              <div className='col'>{rs || "-"}</div>
            </div>
            <div className='row'>
              <div className='col'>Nom commercial</div>
              <div className='col'>{commercialName || "-"}</div>
            </div>
            <div className='row'>
              <div className='col'>Siren</div>
              <div className='col'>{numberWithSpaces(siren) || "-"}</div>
            </div>
            <div className='row'>
              <div className='col'>Siret</div>
              <div className='col'>{numberWithSpaces(siret) || "-"}</div>
            </div>
            <div className='row'>
              <div className='col'>DUNS number</div>
              <div className='col'>{duns || "-"}</div>
            </div>
            <div className='row'>
              <div className='col'>TVA intracommunautaire</div>
              <div className='col'>{tva || "-"}</div>
            </div>
            <div className='row'>
              <div className='col'>Nature de l'établissement</div>
              <div className='col'>{nature || "-"}</div>
            </div>
            <div className='row'>
              <div className='col'>Date de création</div>
              <div className='col'>{created || "-"}</div>
            </div>
            <div className='row'>
              <div className='col'>Activité</div>
              <div className='col'>{activity || "-"}</div>
            </div>
            <div className='row'>
              <div className='col'>Forme juridique</div>
              <div className='col'>{legalStatus || "-"}</div>
            </div>
            <div className='row'>
              <div className='col'>Capital social</div>
              <div className='col'>{capital || "-"} €</div>
            </div>
            <div className='row'>
              <div className='col'>Tranche effectif INSEE (global)</div>
              <div className='col'>{globalTranche || "-"}</div>
            </div>
            <div className='row'>
              <div className='col'>Tranche effectif établissement</div>
              <div className='col'>{effectifTranche || "-"}</div>
            </div>
          </div>
        }
      />
    )
  );
}
