import React from "react";
import "./NoData.scss";

const NoData = () => {
  return (
    <div className={"no-data-component"}>
      Aucune donnée n'est disponible pour cet affichage
    </div>
  );
};

export default NoData;
