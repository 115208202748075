import React, { Component } from "react";
import axios from "axios";
import Modal from "react-responsive-modal";
import { TabContent, TabLink, Tabs } from "react-tabs-redux";
import ComponentSlider from "@kapost/react-component-slider";
import { ParamsContext } from "../../../hooks/ParamsContext";

import Loader, { types } from "../loader/loaderComponent";
import CheckBoxView from "./components/modalViews/checkbox/CheckBoxModalComponent";
import CheckBoxTreeView from "./components/modalViews/checkboxTreeList/CheckBoxTreeListModalComponent";
import MyLocation from "./components/modalViews/myLocation/myLocationModalComponent";
import CheckBoxIconView from "./components/modalViews/checkboxIcon/CheckBoxIconModalComponent";
import CheckBoxTwoChoicesView from "./components/modalViews/checkboxTwoChoices/CheckBoxTwoChoicesModalComponent";
import CheckBoxListView from "./components/modalViews/checkboxList/CheckBoxListModalComponent";
import RadioIconView from "./components/modalViews/radioIcon/RadioIconModalComponent";
import RangeDate from "./components/modalViews/rangeDate/RangeDateModalComponent";
import RangeNumber from "./components/modalViews/rangeNumber/RangeNumberModalComponent";
import Slider from "./components/modalViews/slider/sliderModalComponent";
import SearchView from "./components/modalViews/search/searchModalComponent";
import MultipleTextView from "./components/modalViews/multipleText/MultipleText";
import SwitchModalComponent from "./components/modalViews/switch/SwitchModalComponent";
import SelectModalComponent from "./components/modalViews/select/SelectModalComponent";
import TreeGroupComponent from "./components/modalViews/treeGroup/TreeGroup";
import { extractStructure } from "./../CreateList/QueryTool";

import "./ModalWindow.scss";

const renderLeftArrow = () => <span className='icon-arrow-right' />;
const renderRightArrow = () => <span className='icon-arrow-right' />;

class ModalWindow extends Component {
  static contextType = ParamsContext;

  config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isLoading: false,
    };

    this.tabTreeGroup = [];

    this.onGetProps = this.onGetProps.bind(this);
    this.getModalView = this.getModalView.bind(this);
  }

  onOpenModal = () => {
    let url =
      process.env.REACT_APP_API_URL +
      "modal?modalFilterId=" +
      this.props.modalFilterId;

    const query = new URLSearchParams();
    Object.keys(this.context.params).forEach((j) => {
      query.append(j, this.context.params[j]);
    });

    axios
      .post(url, query, this.config)
      .then((r) => {
        this.setState({
          ...this.state,
          open: true,
          currentModal: r.data,
          isLoading: false,
        });
        this.onGetProps(this.props, true);
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
      });
  };

  getOperator(c) {
    switch (c.type) {
      case "SEARCH":
        return "EQ";
      case "RANGE":
        return "EQ";
      default:
        if (c.concepts[0].family === "STRING") {
          return "EQ";
        }
        if (c.concepts[0].family === "NUMBER") {
          return "NEQ";
        }
        break;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.open &&
      prevState.isLoading === true &&
      !this.state.waiting
    ) {
      this.setState({ ...this.state, waiting: true });
      this.onOpenModal();
      setTimeout(() => {
        this.setState({ ...this.state, waiting: false });
      }, 1000);
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    !this.state.isLoading && this.setState({ isLoading: newProps.isLoading });
    this.context.setParams(newProps.currentQuery);
  }

  onGetProps(props, onMount) {
    // Need to set values
    const tabs = this.state.currentModal.tabControl.filterTabs;
    const criteiasObject = {};

    tabs.forEach((t) => {
      if (t.criterias[0]) {
        criteiasObject[t.id] = {
          conceptType: t.criterias[0].concepts[0].type === "DIMENSION" ? 0 : 1,
          conceptName: t.criterias[0].concepts[0].conceptName,
          operator: this.getOperator(t.criterias[0]),
          criteriaType: t.criterias[0].type,
          values: [],
          exclude: t.criterias[0].option.exclude,
          logicalOperator:
            t.criterias[0].concepts[0] &&
            t.criterias[0].concepts[0].logicalOperator
              ? t.criterias[0].concepts[0].logicalOperator
              : "OR",
        };
        if (t.conceptClauses[0]) {
          let selectedValues = [];

          if (t.conceptClauses[0].filters) {
            t.conceptClauses[0].filters.forEach((f) => {
              let values = f.expressions;
              // If it's range
              if (t.criterias[0].type === "RANGE") {
                values = [];
                const rangeVal = {
                  operator: f.operator,
                  values: f.expressions,
                };

                // If it's date
                if (t.criterias[0].concepts[0].family === "DATE") {
                  rangeVal.values.forEach((ex, i) => {
                    rangeVal.values[i] = ex.replace(
                      /(\d{4})(\d{2})(\d{2})/,
                      "$3/$2/$1"
                    );
                  });
                }

                values.push(rangeVal);
              }
              if (t.criterias[0].type === "SLIDER") {
                values = [];
                values.push({
                  min: f.expressions[0],
                  max: f.expressions[1],
                });
              }
              if (t.criterias[0].type === "CHECKBOX_TREE_LIST") {
                values = [];
                values.push({
                  ref: f.conceptRef,
                  value: f.expressions[0],
                });
              }
              selectedValues.push(...values);
            });
          }

          criteiasObject[t.id].values = selectedValues;
        }
      }
    });
  }

  onCloseModal = () => {
    !this.state.isLoading && this.setState({ open: false });
  };

  // Get modal view
  getModalView(type, c, cc, i, tab) {
    const structure = extractStructure(
      this.state.currentModal.tabControl.filterTabs
    );

    if (c.option.useTreeGroupCriteria) {
      if (this.tabTreeGroup.indexOf(tab.id) > -1) {
        return null;
      }

      this.tabTreeGroup.push(tab.id);
      return (
        <TreeGroupComponent
          addQuery={this.props.addQuery}
          removeQuery={this.props.removeQuery}
          removeAllQuery={this.props.removeAllQuery}
          structure={structure}
          key={i}
          content={c}
          concept={cc}
          tab={tab}
          modal={this.state.currentModal.tabControl}
        />
      );
    } else {
      switch (type) {
        case "CHECKBOX":
          return (
            <CheckBoxView
              updateQuery={this.props.updateQuery}
              key={i}
              content={c}
              concept={cc}
              tab={tab}
              modal={this.state.currentModal.tabControl}
            />
          );
        case "CHECKBOX_TREE_LIST":
          return (
            <CheckBoxTreeView
              updateQuery={this.props.updateQuery}
              key={i}
              content={c}
              concept={cc}
              tab={tab}
              modal={this.state.currentModal.tabControl}
            />
          );
        case "AROUND_ME":
          return (
            <MyLocation
              updateQuery={this.props.updateQuery}
              key={i}
              content={c}
              concept={cc}
              tab={tab}
              modal={this.state.currentModal.tabControl}
            />
          );
        case "CHECKBOX_ICON":
          return (
            <CheckBoxIconView
              updateQuery={this.props.updateQuery}
              key={i}
              content={c}
              concept={cc}
              tab={tab}
              modal={this.state.currentModal.tabControl}
            />
          );
        case "CHECKBOX_TWO_CHOICES":
          return (
            <CheckBoxTwoChoicesView
              updateQuery={this.props.updateQuery}
              key={i}
              content={c}
              concept={cc}
              tab={tab}
              modal={this.state.currentModal.tabControl}
            />
          );
        case "CHECKBOX_LIST":
          return (
            <CheckBoxListView
              updateQuery={this.props.updateQuery}
              key={i}
              content={c}
              concept={cc}
              tab={tab}
              modal={this.state.currentModal.tabControl}
            />
          );
        case "SLIDER":
          return (
            <Slider
              updateQuery={this.props.updateQuery}
              key={i}
              content={c}
              concept={cc}
              tab={tab}
              modal={this.state.currentModal.tabControl}
            />
          );
        case "RADIO_ICON":
          return (
            <RadioIconView
              updateQuery={this.props.updateQuery}
              key={i}
              content={c}
              concept={cc}
              tab={tab}
              modal={this.state.currentModal.tabControl}
            />
          );
        case "RANGE":
          if (c.concepts[0].family === "DATE") {
            return (
              <RangeDate
                updateQuery={this.props.updateQuery}
                key={i}
                content={c}
                concept={cc}
                tab={tab}
                modal={this.state.currentModal.tabControl}
              />
            );
          } else if (c.concepts[0].family === "NUMBER") {
            return (
              <RangeNumber
                updateQuery={this.props.updateQuery}
                sendQueryWithTimer={this.sendQueryWithTimer}
                key={i}
                content={c}
                concept={cc}
                tab={tab}
                modal={this.state.currentModal.tabControl}
              />
            );
          }
          return null;
        case "SEARCH":
          return (
            <SearchView
              updateQuery={this.props.updateQuery}
              key={i}
              content={c}
              concept={cc}
              tab={tab}
              modal={this.state.currentModal.tabControl}
            />
          );
        case "MULTIPLE_TEXT":
          return (
            <MultipleTextView
              updateQuery={this.props.updateQuery}
              key={i}
              content={c}
              concept={cc}
              tab={tab}
              modal={this.state.currentModal.tabControl}
            />
          );
        case "SWITCH":
          return (
            <SwitchModalComponent
              updateQuery={this.props.updateQuery}
              key={i}
              content={c}
              concept={cc}
              tab={tab}
              modal={this.state.currentModal.tabControl}
            />
          );
        case "SELECT":
          return (
            <SelectModalComponent
              updateQuery={this.props.updateQuery}
              key={i}
              content={c}
              concept={cc}
              tab={tab}
              modal={this.state.currentModal.tabControl}
            />
          );
        default:
          return null;
      }
    }
  }

  // Functions for single tab modal
  getModalInfo(cn) {
    return (
      cn.info && (
        <div className='modal-title tab-description'>
          <p>{cn.info}</p>
          {cn.subInfo && <p className='sub-info'>{cn.subInfo}</p>}
        </div>
      )
    );
  }

  getModalContent(tab) {
    let cc = tab.conceptClauses;
    this.tabTreeGroup = [];

    return tab.criterias.map((c, i) => {
      if (c) {
        return this.getModalView(c.type, c, cc, i, tab);
      }
      return null;
    });
  }

  // Functions for multitab modal
  getTabHeading(title) {
    if (!title) {
      return null;
    }
    return (
      <div className='multi-tab-heading'>
        <span className='title'>{title}</span>
      </div>
    );
  }

  addTabsHeading(el) {
    return el.tabControl.filterTabs.map((tab, i) => {
      return (
        <TabLink
          className={tab.disable === true ? "disable" : ""}
          key={i}
          to={tab.id}
        >
          <span className='tab-heading-content'>
            {tab.id === "location" ? (
              <span className='icon icon-map-pin'></span>
            ) : null}
            {tab.title}
          </span>
        </TabLink>
      );
    });
  }

  getTabDesc(tab) {
    return (
      tab && (
        <div className='tab-description'>
          <p>{tab.info}</p>
          {tab.subInfo && <p className='sub-info'>{tab.subInfo}</p>}
        </div>
      )
    );
  }

  addTabsContent(el) {
    return el.tabControl.filterTabs.map((tab, i) => {
      let cc = tab.conceptClauses;
      this.tabTreeGroup = [];
      return (
        <TabContent key={i} for={tab.id}>
          {this.getTabDesc(tab)}
          {tab.criterias.map((c, i) => {
            return this.getModalView(c.type, c, cc, i, tab);
          })}
        </TabContent>
      );
    });
  }

  getModal() {
    if (this.state.currentModal != null) {
      if (this.state.currentModal.tabControl.singleTab === true) {
        return (
          <div className='single-tab-modal'>
            <Loader isLoading={this.state.isLoading} type={types.MODAL} />
            <header>
              <span>
                {this.state.currentModal.tabControl.filterTabs[0].title}
              </span>
            </header>
            <div className='single-tab-modal-content'>
              {this.getModalInfo(
                this.state.currentModal.tabControl.filterTabs[0]
              )}
              {this.getModalContent(
                this.state.currentModal.tabControl.filterTabs[0]
              )}
            </div>
          </div>
        );
      } else {
        return (
          <div className='modal-tabs'>
            <Loader isLoading={this.state.isLoading} type={types.MODAL} />
            {this.getTabHeading(this.state.currentModal.title)}
            <Tabs>
              <div className='tab-headings'>
                <ComponentSlider
                  renderLeftArrow={renderLeftArrow}
                  renderRightArrow={renderRightArrow}
                >
                  {this.addTabsHeading(this.state.currentModal)}
                </ComponentSlider>
              </div>
              {this.addTabsContent(this.state.currentModal)}
            </Tabs>
          </div>
        );
      }
    }
  }

  render() {
    const { open } = this.state;
    return (
      <div className='modal-btn-holder'>
        <button
          className={
            "modal-btn" +
            (open ? " active" : "") +
            (this.props.active ? " active-in-filter" : "")
          }
          onClick={this.onOpenModal}
        >
          {this.props.modalTitle}
        </button>
        <Modal
          classNames={{
            modal: "modal",
          }}
          open={open}
          onClose={this.onCloseModal}
          focusTrapped={false}
          center
        >
          {this.getModal()}
        </Modal>
      </div>
    );
  }
}

export default ModalWindow;
