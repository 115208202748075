import React, { Component } from "react";
import "./DoughnutGraphicComponent.scss";
import { Doughnut } from "react-chartjs-2";
import NoData from "../../../NoData/NoData";

export default class DoughnutGraphicComponent extends Component {
  myRef;

  backgroundColor = [
    "#8dd674",
    "#ffb400",
    "#f7776b",
    "#a9524e",
    "#c8d4e8",
    "#91a8d0",
    "#4e5c7b",
    "#fbe337",
    "#AF33FF",
    "#FF33A5",
  ];

  constructor(props) {
    super(props);

    this.myRef = null;
    this.state = {
      legend: null,
      selectedLegend: null,
      labelsData: [
        {
          color: "#8dd674",
          value: 65,
          title: "Diesel",
        },
        {
          color: "#ffb400",
          value: 65,
          title: "Essence",
        },
        {
          color: "#f7776b",
          value: 59,
          title: "Electricité",
        },
        {
          color: "#a9524e",
          value: 80,
          title: "Ethanol",
        },
        {
          color: "#c8d4e8",
          value: 81,
          title: "Gaz",
        },
        {
          color: "#91a8d0",
          value: 56,
          title: "Hybride non rechargeable",
        },
        {
          color: "#4e5c7b",
          value: 55,
          title: "Hybride rechargeable",
        },
      ],
      data: null,
      options: {
        legend: {
          display: false,
        },
        tooltips: {
          enabled: false,
        },
        legendCallback: (chart) => {
          if (this.state.selectedLegend !== null) {
            const div = [];
            const item = this.state.labelsData[this.state.selectedLegend];
            div.push('<div class="legend">');
            div.push(
              `<span style="background-color: ${item.color}"></span>${item.title}: ${item.formatValue}`
            );
            div.push("</div>");
            return div.join("");
          }
          return null;
        },
        onHover: (e) => {
          if (this.myRef.chartInstance.active[0]) {
            this.setState(
              {
                selectedLegend: this.myRef.chartInstance.active[0]._index,
              },
              () => {
                this.createLeg();
              }
            );
            e.target.style.cursor = "pointer";
          } else {
            e.target.style.cursor = "default";
          }
        },
        datalabels: {
          display: true,
        },
        title: {
          display: true,
          text: ``,
          fontColor: "#4a63a1",
          fontSize: "14",
          fontStyle: "bold",
        },
        scales: {
          xAxes: [
            {
              display: false,
            },
          ],
        },
      },
    };

    this.createMarkup = this.createMarkup.bind(this);
    this.setNewData = this.setNewData.bind(this);
  }

  componentDidMount() {
    this.createLeg();
    this.setNewData(this.props.item);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setNewData(newProps.item);
  }

  setNewData(chart) {
    if (!chart) {
      return null;
    }

    const newOptions = this.state.options;
    // Set title
    newOptions.title.text = chart.title;

    const labelsData = [];

    const data = {
      labels: [],
      datasets: [
        {
          //backgroundColor: "rgba(255,99,132,0.2)",
          hoverBorderColor: "#e6e6e6",
          backgroundColor: [
            "#8dd674",
            "#ffb400",
            "#f7776b",
            "#a9524e",
            "#c8d4e8",
            "#91a8d0",
            "#4e5c7b",
            "#fbe337",
            "#AF33FF",
            "#FF33A5",
          ],
          data: [],
        },
      ],
    };

    chart.values.forEach((s, i) => {
      data.datasets[0].data.push(s.value);

      let color = this.backgroundColor[i];

      if (i >= this.backgroundColor.length) {
        const indexColor = i - this.backgroundColor.length;
        color = this.backgroundColor[indexColor];
      }

      labelsData.push({
        color: color,
        value: s.value,
        title: chart.legends[s.nameValue],
        formatValue: s.formatValue,
        metricName: chart.metricName,
      });
    });

    this.setState({
      data,
      labelsData,
      options: newOptions,
    });
  }

  setTextInputRef(element) {
    this.myRef = element;
  }

  createLeg() {
    const leg = this.generateLegend();
    this.setState({ legend: leg });
  }

  generateLegend() {
    if (!this.myRef) return null;
    return this.myRef.chartInstance.generateLegend();
  }

  createMarkup() {
    return { __html: this.state.legend };
  }

  renderDoughnut() {
    if (this.props.item.values && !this.props.item.values.length) {
      return (
        <div className={"no-slices"}>
          <div className='doughnut-title mb-10'>{this.props.item.title}</div>
          <NoData />
        </div>
      );
    }

    return (
      <React.Fragment>
        <Doughnut
          ref={(element) => this.setTextInputRef(element)}
          options={this.state.options}
          data={this.state.data}
          height={130}
        />
        <div
          className='custom-legend'
          dangerouslySetInnerHTML={this.createMarkup()}
        />
      </React.Fragment>
    );
  }

  render() {
    if (!this.state.data) {
      return null;
    }

    return <div className='with-bg'>{this.renderDoughnut()}</div>;
  }
}
