import React, { Component } from "react";
import axios from "axios";

class CitiesAutocomplete extends Component {
  BASE_DOMAIN = process.env.REACT_APP_API_URL;

  constructor(props) {
    super(props);
    this.state = {
      searchStr: "",
      autocompleteCities: [],
      openAutocomplete: false,
      loadingAutocomplete: false,
      selectedItemIndex: null,
    };

    this.timer = null;
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  changeSearch = (e) => {
    this.setState(
      {
        searchStr: e.target.value,
      },
      () => {
        // Create query and send it
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
          this.setState(
            {
              loadingAutocomplete: this.state.searchStr.length > 0,
            },
            () => {
              this.getCitiesByStr();
            }
          );
        }, 400);
      }
    );
  };

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.clearAndClose();
    }
  };

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  getCitiesByStr() {
    if (this.state.searchStr.length > 0 && this.props.conceptName) {
      const url = `dimensionSearch?conceptName=${this.props.conceptName}&keyword=${this.state.searchStr}`;

      const config = {
        headers: { "Access-Control-Allow-Origin": "*" },
        maxRedirects: 0,
      };

      axios.get(this.BASE_DOMAIN + url, config).then((r) => {
        this.setState({
          loadingAutocomplete: false,
          openAutocomplete: true,
          autocompleteCities: r.data,
          selectedItemIndex: null,
        });
      });
    } else {
      this.setState({
        loadingAutocomplete: false,
        openAutocomplete: false,
        selectedItemIndex: null,
        autocompleteCities: [],
      });
    }
  }

  clearAndClose = () => {
    this.setState({
      autocompleteCities: [],
      selectedItemIndex: null,
      openAutocomplete: false,
    });
  };

  selectCity = (city) => {
    this.props.handleSelectCity(city);
    this.setState(
      {
        searchStr: "",
      },
      () => {
        this.clearAndClose();
      }
    );
  };

  handleKeyDown = (e) => {
    switch (e.key) {
      case "Enter":
        if (this.state.selectedItemIndex !== null) {
          this.selectCity(
            this.state.autocompleteCities[this.state.selectedItemIndex]
          );
        }
        break;
      case "ArrowDown":
        if (this.state.autocompleteCities.length) {
          let index = 0;
          if (this.state.selectedItemIndex !== null) {
            index = this.state.selectedItemIndex + 1;
            if (index > this.state.autocompleteCities.length - 1) {
              index = this.state.autocompleteCities.length - 1;
            }
          }
          this.setState({
            selectedItemIndex: index,
          });
        }
        break;
      case "ArrowUp":
        if (this.state.autocompleteCities.length) {
          let index = this.state.autocompleteCities.length - 1;
          if (this.state.selectedItemIndex !== null) {
            index = this.state.selectedItemIndex - 1;
            if (index < 0) {
              index = 0;
            }
          }
          this.setState({
            selectedItemIndex: index,
          });
        }
        break;
      default:
        break;
    }
  };

  getAutocomplete() {
    if (
      this.state.autocompleteCities.length === 0 &&
      !this.state.openAutocomplete
    ) {
      return null;
    }
    if (
      this.state.autocompleteCities.length === 0 &&
      this.state.openAutocomplete
    ) {
      return (
        <div className='cities-autocomplete text-center'>
          <p className='no-results'>Aucun résultat!</p>
        </div>
      );
    }

    return (
      <div className='cities-autocomplete'>
        <>
          <ul>
            {this.state.autocompleteCities.map((city, i) => {
              return (
                <li
                  className={
                    "item " +
                    (this.state.selectedItemIndex === i ? "selected" : null)
                  }
                  key={i}
                  onClick={() => this.selectCity(city)}
                >
                  {city.name}
                </li>
              );
            })}
          </ul>
        </>
      </div>
    );
  }

  render() {
    if (!this.props) {
      return null;
    }
    return (
      <div className='search-filter search-cities' ref={this.setWrapperRef}>
        <label className='search-input'>
          <input
            type='text'
            autoFocus={false}
            name='search'
            placeholder={
              this.props.content
                ? this.props.content.option.defaultLabel
                : "Entrez le nom d'une ville"
            }
            value={this.state.searchStr}
            onChange={this.changeSearch}
            onKeyDown={this.handleKeyDown}
          />
        </label>
        {this.state.loadingAutocomplete ? (
          <div className='loader'>
            <div className='lds-dual-ring'></div>
          </div>
        ) : null}
        {this.getAutocomplete()}
      </div>
    );
  }
}

export default CitiesAutocomplete;
