import React, { useEffect, useState } from "react";
import { Col, Row } from "react-flexbox-grid";

import ListsTableComponent from "../../ListsTable/ListsTableComponent";
import CreateAdminUser from "./CreateAdminUser";

export default function AdminUser({
  accountId,
  pageId,
  component,
  sendQueryToApi,
}) {
  const [userToUpdate, setUserToUpdate] = useState(null);

  useEffect(() => {
    setUserToUpdate(null);
  }, [component]);

  return (
    <div className='scrollAuto'>
      <div className='page-heading'>
        <Row className='ovv'>
          <Col sm={12} md={9}>
            <h2>Gestion des accès</h2>
            <p>Configurez les différents accès utilisateurs à votre compte.</p>
            {component.options.showStat && (
              <p>
                Nombre d'utilisateurs associés au compte :{" "}
                {component.options.userCount}/{component.options.userQuota}
              </p>
            )}
          </Col>
          {component.options.canCreate && component.options.quotaOk && (
            <Col md={3} className='text-right'>
              <button
                className='btn btn-primary'
                onClick={() => {
                  setUserToUpdate({
                    email: "",
                    roles: [],
                    canRoles: component.options.canAddRoles,
                  });
                }}
              >
                Créer un accès
              </button>
            </Col>
          )}
          {userToUpdate && (
            <CreateAdminUser
              closePopup={() => {
                setUserToUpdate(null);
              }}
              user={userToUpdate}
              accountId={accountId}
              options={component.options}
              sendQueryToApi={(params) =>
                sendQueryToApi({
                  ...params,
                  tabId: "user",
                })
              }
            />
          )}
        </Row>
      </div>
      <ListsTableComponent
        deleteModalTitle='Supprimer un utilisateur'
        deleteModalMessage='Voulez-vous vraiment supprimer cet utilisateur ?'
        accountId={accountId}
        pageId={pageId}
        table={component}
        sendQueryToApi={(params) =>
          sendQueryToApi({
            ...params,
            tabId: "user",
          })
        }
        editResource={(id) => {
          const user = component.rowData.find((d) => d.data.id === id).data;
          setUserToUpdate({
            email: user.email,
            roles: user.roles,
            canRoles: component.options.canEditRoles,
            segmentation: user.segmentation,
            editRoles: user.showEditRoles,
            quotaSiret: user.quotaSiret,
            quotaEmailNominatif: user.quotaEmailNominatif,
            organization: user.organization,
          });
        }}
      />
    </div>
  );
}
