import React from "react";
import Modal from "react-responsive-modal";
import Loader, { types } from "../loader/loaderComponent";
import "./PopUpModalComponent.scss";

export default function PopUpModal({
  title,
  description,
  content,
  open,
  closeModal,
  isLoading,
}) {
  return (
    <Modal
      classNames={{ modal: "modal" }}
      open={open}
      onClose={closeModal}
      focusTrapped={false}
      center
    >
      <Loader isLoading={isLoading} type={types.MODAL} />
      <div className='form-modal single-tab-modal'>
        <header>
          <span>{title}</span>
        </header>
        <div className='single-tab-modal-content'>
          <p>{description}</p>
          {!!content && content}
        </div>
      </div>
    </Modal>
  );
}
