import React, { useState, useRef, useEffect } from "react";

export default function SearchHeading({
  hideSearch,
  searchStr,
  handlerSearch,
}) {
  const [searchOpen, setSearchOpen] = useState(false);
  const [currentSearchStr, setSearchStr] = useState(searchStr);

  useEffect(() => {
    setSearchStr(searchStr);
  }, [searchStr]);

  const ref = useRef();

  useOnClickOutside(ref, () => setSearchOpen(false));

  const toggle = () => {
    setSearchOpen(true);
  };

  return (
    !hideSearch && (
      <div className='search-heading'>
        <div className='row-table'>
          <label
            onClick={toggle}
            className={"search-table" + (searchOpen ? " active" : "")}
            ref={ref}
          >
            <span className='icon-search' />
            <input
              className='input'
              type='text'
              value={currentSearchStr}
              onChange={(e) => {
                setSearchStr(e.target.value.toLowerCase());
                handlerSearch(e.target.value.toLowerCase());
              }}
              autoFocus={false}
              name='rechercher'
            />
          </label>
        </div>
      </div>
    )
  );
}

function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}
