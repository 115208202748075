import React from "react";
import Dropdown from "../Dropdown/Dropdown";

export default function ActionnarySubsidiary({ actionnarySubsidiary }) {
  const { actionnaryNb, subsidiaryNb, actionnaries, subsidiaries } =
    actionnarySubsidiary;
  return (
    actionnarySubsidiary && (
      <Dropdown
        title={<h2>Filiales &amp; Actionnaires</h2>}
        content={
          <div className='actionnary'>
            <div className='row '>
              <div className='col card'>
                <span className={`icon icon-actionnaire path*`} />
                <p>Nombre d'actionnaires</p>
                <p>{actionnaryNb}</p>
              </div>
              <div className='col card'>
                <span className={`icon icon-filiale`} />
                <p>Nombre de filiales</p>
                <p>{subsidiaryNb}</p>
              </div>
            </div>
            {!!actionnaries?.length && (
              <div className='row'>
                <table className='table-holder'>
                  <thead>
                    <tr>
                      <th>Actionnaire</th>
                      <th>DUNS Number</th>
                      <th>Siren</th>
                      <th>Pays</th>
                      <th>Détention</th>
                      <th>Taux de détention</th>
                      <th>Type de personne</th>
                    </tr>
                  </thead>
                  <tbody>
                    {actionnaries.map((a, index) => (
                      <tr key={index}>
                        <td>{a.name}</td>
                        <td>{a.duns}</td>
                        <td>{a.siren}</td>
                        <td>{a.country}</td>
                        <td>{a.detention}</td>
                        <td>{a.taux}</td>
                        <td>{a.type}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            {!!subsidiaries?.length && (
              <div className='row'>
                <table className='table-holder'>
                  <thead>
                    <tr>
                      <th>Filiales</th>
                      <th>DUNS Number</th>
                      <th>Siren</th>
                      <th>Pays</th>
                      <th>Participation</th>
                      <th>Taux de participation</th>
                    </tr>
                  </thead>
                  <tbody>
                    {subsidiaries.map((a, index) => (
                      <tr key={index}>
                        <td>{a.name}</td>
                        <td>{a.duns}</td>
                        <td>{a.siren}</td>
                        <td>{a.country}</td>
                        <td>{a.participation}</td>
                        <td>{a.taux}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        }
      />
    )
  );
}
