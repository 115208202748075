import React, { Component } from "react";
import ModalFooter from "../../ModalFooter/ModalFooterComponent";
import "./searchModalComponent.scss";
import CitiesAutocomplete from "../citiesAutocomplete/citiesAutocomplete";
import ResetFilter from "../reset/ResetFilters";
class SearchView extends Component {
  BASE_DOMAIN = process.env.REACT_APP_API_URL;

  constructor(props) {
    super(props);
    this.state = {
      exclude: false,
      selectedItems: [],
    };

    this.addNewCity = this.addNewCity.bind(this);
  }

  componentDidMount() {
    this.setSelectedValues(this.props);
    if (this.props.concept && this.props.concept.length > 0) {
      let filters = this.props.concept[0].filters;
      let newEx = filters[0].not;
      this.setState({
        exclude: newEx,
      });
    }
  }

  componentWillUnmount() {}

  UNSAFE_componentWillReceiveProps(np) {
    this.setSelectedValues(np);
  }

  setSelectedValues = (props) => {
    if (props.concept) {
      if (props.concept[0] && props.concept[0].filters) {
        const selectedItems = [];

        props.concept[0].filters.forEach((f) => {
          f.expressions.forEach((expression) => {
            selectedItems.push({
              name: expression,
              code: expression,
            });
          });
        });

        this.setState({
          selectedItems,
        });
      } else {
        this.setState({
          selectedItems: [],
        });
      }
    }
  };

  removeSelectedItem = (el) => {
    let index = -1;
    let selectedItems = this.state.selectedItems;

    this.state.selectedItems.forEach((item, i) => {
      if (item.code === el.code) {
        index = i;
      }
    });

    if (index > -1) {
      selectedItems.splice(index, 1);
      this.setState(
        {
          selectedItems,
        },
        () => {
          this.sendQuery();
        }
      );
    }
  };

  addNewCity = (c) => {
    const selectedItems = this.state.selectedItems;
    let index = -1;

    selectedItems.forEach((el, i) => {
      if (el.code === c.code) {
        index = i;
      }
    });

    if (index < 0) {
      selectedItems.push(c);

      this.setState(
        {
          selectedItems: selectedItems,
        },
        () => {
          this.sendQuery();
        }
      );
    }
  };

  refreshData = () => {
    this.setState(
      {
        selectedItems: [],
      },
      () => {
        this.sendQuery();
      }
    );
  };

  sendQuery() {
    const selectedItems = this.state.selectedItems.map((el) => el.code);

    this.props.updateQuery(
      selectedItems.length ? [selectedItems] : [],
      this.props.content,
      this.state.exclude,
      this.props.tab,
      this.props.modal
    );
  }

  handler = (checked) => {
    this.setState(
      {
        exclude: checked,
      },
      () => {
        if (this.state.selectedItems.length) {
          this.sendQuery();
        }
      }
    );
  };

  getFooter() {
    return (
      <ModalFooter
        handleChange={this.handler}
        exclude={this.state.exclude}
        option={this.props.content.option}
      />
    );
  }

  getSelectedItems() {
    if (this.state.selectedItems.length === 0) {
      return null;
    }
    return this.state.selectedItems.map((el, i) => {
      return (
        <div className='city-item' key={i}>
          {el.name}
          <span
            className='remove-item'
            onClick={() => this.removeSelectedItem(el)}
          >
            <span className='icon icon-close'></span>
          </span>
        </div>
      );
    });
  }

  getSelectedItemsHolder() {
    return (
      <div className='cities-wrapper'>
        <ResetFilter handleDelete={this.refreshData} />
        <div className='col-holder'>
          <>
            <div className='cities-list'>{this.getSelectedItems()}</div>
          </>
        </div>
      </div>
    );
  }

  render() {
    if (!this.props) {
      return null;
    }
    return (
      <div className='modal search-modal'>
        <div
          className={
            "modal-content" + (this.state.exclude ? " exclude" : " include")
          }
        >
          <CitiesAutocomplete
            conceptName={this.props.content.concepts[0].conceptName}
            content={this.props.content}
            handleSelectCity={this.addNewCity}
          />
          {this.getSelectedItemsHolder()}
        </div>
        {this.getFooter()}
      </div>
    );
  }
}

export default SearchView;
