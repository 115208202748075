import React, { Component } from "react";
import "./ContactModalComponent.scss";
import Modal from "react-responsive-modal";
import { Col, Row } from "react-flexbox-grid";
import axios from "axios";

const formInputs = [
  {
    name: "societe",
    pattern: ".*",
    type: "text",
    required: "required",
    placeholder: "Raison sociale*",
  },
  {
    name: "prenom",
    pattern: ".*",
    type: "text",
    required: "required",
    placeholder: "Prénom*",
  },
  {
    name: "nom",
    pattern: ".*",
    type: "text",
    required: "required",
    placeholder: "Nom*",
  },
  {
    name: "telephone",
    pattern: "[0-9]{10}",
    type: "phone",
    required: "required",
    placeholder: "Téléphone*",
  },
  {
    name: "email",
    pattern: "[[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$",
    type: "email",
    required: "required",
    placeholder: "Mail*",
  },
  {
    name: "campagne",
    type: "hidden",
  },
];

export default class ContactModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      message: "",
      isValidated: false,
      error: false,
      campagne: "",
      champs_27: "2",
    };

    this.closeModal = this.closeModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.clearForm();
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ open: props.open, campagne: props.refCampagne });
  }

  clearForm() {
    formInputs.forEach((input) => {
      this.setState({
        [input.name]: "",
      });
    });
    this.setState({ message: "" });
  }

  closeModal() {
    this.setState({ open: false });
    this.setState({ isValidated: false });
    this.clearForm();
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState(
      {
        error: false,
      },
      () => {
        let data = {
          societe: this.state.societe,
          email: this.state.email,
          prenom: this.state.prenom,
          nom: this.state.nom,
          commentaire: this.state.message,
          telephone: this.state.telephone,
          campagne: this.state.campagne,
          champs_27: this.state.champs_27,
        };

        axios
          .post(
            `${process.env.REACT_APP_API_URL}myAccount/formulaire/crm`,
            data
          )
          .then(() => {
            this.setState({
              isValidated: true,
            });
          })
          .catch((error) => {
            this.setState({
              error: true,
            });
          });
      }
    );
  }

  handleChangeValue(e, name) {
    this.setState({
      [name]: e.target.value,
    });
  }

  renderInputs() {
    return formInputs.map((input, key) => {
      return (
        <div className='input-row' key={key}>
          <input
            className='input'
            id={input.name}
            type={input.type}
            name={input.name}
            placeholder={input.placeholder}
            value={this.state[input.name] ? this.state[input.name] : ""}
            onChange={(e) => this.handleChangeValue(e, input.name)}
            pattern={input.pattern}
            required={input.required}
          />
        </div>
      );
    });
  }

  render() {
    return (
      <Modal
        open={this.state.open}
        onClose={() => this.closeModal()}
        focusTrapped={false}
        center
      >
        <div
          className={
            "validated-overlay " + (this.state.isValidated ? "open" : "")
          }
        >
          <div className='texts-container'>
            <h2 className='validated-title'>Merci!</h2>
            <p>Votre demande a bien été prise en compte.</p>
            <p>Nous allons vous recontacter rapidement.</p>
            <p>
              <span className={"icon icon-step-confirmation"} />
            </p>
          </div>
        </div>
        <div className='form-modal single-tab-modal'>
          <header>
            <span>Formulaire de contact</span>
          </header>
          <div className='single-tab-modal-content'>
            <form onSubmit={this.handleSubmit}>
              <Row>
                <Col sm={12} md={6} className={"inputs-container"}>
                  {this.renderInputs()}
                </Col>
                <Col sm={12} md={6}>
                  <textarea
                    id='commentaire'
                    name='commentaire'
                    placeholder='Message (optionnel)'
                    value={this.state.message}
                    onChange={(e) => this.handleChangeValue(e, "message")}
                  ></textarea>
                </Col>
              </Row>
              <p>* Champs obligatoires</p>
              <p>
                En validant ce formulaire, j'accepte la{" "}
                <a
                  className='fake-link link-entry'
                  href='https://www.manageo.com/politique-gestion-donnees-personnelles'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  politique de confidentialité.
                </a>
              </p>
              <button type='submit' className='btn btn-primary'>
                Valider
              </button>
              <span
                className={"error-message " + (this.state.error ? "open" : "")}
              >
                Erreur technique, veuillez contacter le support technique.
              </span>
            </form>
          </div>
        </div>
      </Modal>
    );
  }
}
