import React, { useEffect } from "react";
import axios from "axios";
import "../shared/components/Logout/logout.scss";
import logo from "../assets/images/mlist-logo.png";
import IFrames from "@manageo/react-global-sso-logout";

export default function Logout() {
  useEffect(() => {
    setTimeout(() => {
      axios.post("/api/logout").then(function (response) {
        window.location.href =
          response.data.logoutUrl +
          "?returnTo=" +
          encodeURIComponent(window.location.origin);
      });
    }, 4000);
  }, []);

  return (
    <div className='logout'>
      <div className='container'>
        <IFrames />
        <div className='modal'>
          <div className='modal-content'>
            <img alt='logo mList' width={130} src={logo} />
            <span className='modal-text'>Déconnexion en cours...</span>
          </div>
          <div className='loader-container'>
            <div className='lds-ring'>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
