import React, { Component } from "react";
import "./IconChartComponent.scss";
import { defaults } from "react-chartjs-2";
defaults.global.defaultFontFamily = "Raleway";

export default class IconChartComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        title: {
          text: "Dummy",
        },
      },
      data: {},
    };

    this.setNewData = this.setNewData.bind(this);
  }

  componentDidMount() {
    this.setNewData(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setNewData(newProps);
  }

  setNewData(props) {
    const newOptions = this.state.options;
    const data = {};

    // Set title
    newOptions.title.text = props.item.title;

    props.item.values.forEach((b) => {
      data[b.nameValue] = {
        value: b.value,
        formatValue: b.formatValue,
      };
    });

    Object.entries(props.item.legends).forEach((value) => {
      data[value[0]].title = value[1];
    });

    Object.entries(props.item.icons).forEach((value) => {
      data[value[0]].icon = value[1];
    });

    this.setState({
      data,
      options: newOptions,
    });
  }

  getIconsDiv(datas) {
    return <div className='icons-holder'>{this.getIcons(datas)}</div>;
  }

  getIcons(datas) {
    return Object.entries(datas).map((data, i) => {
      return (
        <div key={i} className='icon-holder'>
          <div
            className={"icon icon-" + (data[1].icon ? data[1].icon : "default")}
          ></div>
          <div className='potential'>{data[1].formatValue}</div>
          <div className='title'>{data[1].title}</div>
        </div>
      );
    });
  }

  render() {
    if (Object.keys(this.state.data).length === 0) {
      return null;
    }
    return (
      <div className='icon-chart-section'>
        <h3>{this.state.options.title.text}</h3>
        {this.getIconsDiv(this.state.data)}
      </div>
    );
  }
}
