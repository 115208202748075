import React, { Component } from "react";
import "./ConfirmContentSection.scss";
import { NavLink } from "react-router-dom";
import mCompany from "../../../../../assets/images/m-company.png";

import { Row, Col } from "react-flexbox-grid";

export default class ConfirmContentSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.goToFirst = this.goToFirst.bind(this);
  }

  scrollTop = () => {
    window.scrollTo(0, 0);
  };

  componentDidMount() {
    this.scrollTop();
  }
  getSocial() {
    return (
      <ul className='social-list-section'>
        <li>
          <a
            href='https://www.facebook.com/manageo/'
            target='_blank'
            rel='noopener noreferrer'
            className='circle-border-icon'
          >
            <span className='icon icon-facebook' />
          </a>
        </li>
        <li>
          <a
            href='https://twitter.com/manageo_fr/'
            target='_blank'
            rel='noopener noreferrer'
            className='circle-border-icon'
          >
            <span className='icon icon-twitter' />
          </a>
        </li>
        <li>
          <a
            href='https://www.linkedin.com/company/manageo/'
            target='_blank'
            rel='noopener noreferrer'
            className='circle-border-icon'
          >
            <span className='icon icon-linkedin' />
          </a>
        </li>
        <li>
          <a
            href='https://www.youtube.com/channel/UCMTv_3lu2yqNeFfxRjeA2KA/'
            target='_blank'
            rel='noopener noreferrer'
            className='circle-border-icon'
          >
            <span className='icon icon-youtube' />
          </a>
        </li>
      </ul>
    );
  }
  getSupport() {
    return (
      <div>
        <h2>Support</h2>
        <p>
          Accédez aux ressources mise à votre disposition pour vous aider à
          optimiser votre utilisation de la palteforme.
        </p>
        <div className='support-section'>
          <div className='social-section'>
            <h3>Consultez notre blog ainsi que notre FAQ</h3>
            <ul className='social-list-section'>
              <li>
                <a
                  href='https://www.manageo.fr/blog.html'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='circle-border-icon'
                >
                  <span className='icon icon-blank' />
                </a>
              </li>
              <li>
                <a
                  href='https://support.manageo.com/docs/mlist'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='circle-border-icon'
                >
                  <span className='icon icon-messages' />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className='support-section'>
          <div className='social-section'>
            <h3>Restons connectés ! Suivez-nous sur les réseaux sociaux.</h3>
            {this.getSocial()}
          </div>
        </div>
      </div>
    );
  }

  getContactInfo() {
    return (
      <div className='contact-info-block'>
        <address>
          <h4>Service client</h4>
          <a
            className='contact-info-block-mail'
            href='mailto:contact.aix@altares.com'
          >
            contact.aix@altares.com
          </a>
          <a className='contact-info-block-telephone' href='tel:0442903410'>
            04 42 90 34 10
          </a>
        </address>
      </div>
    );
  }

  getCompanyList() {
    return (
      <div className='companies-list'>
        <div className='company-item'>
          <p>
            Manageo met à votre disposition de nombreuse solutions dédiées à
            votre développement commercial.
          </p>
        </div>
        <div className='company-item'>
          <img src={mCompany} alt='' />
          <p>
            Accédez à toute l'information légale & financière sur + de 10
            millions d'entreprises françaises.
          </p>
          <a
            href='https://www.manageo.fr/savoir.html'
            target='_blank'
            rel='noopener noreferrer'
            className='link'
          >
            <span className='icon icon-plus' />
            En savoir plus
          </a>
        </div>
      </div>
    );
  }

  getPrimaryPanel() {
    const headerPanel =
      this.props.fileFormat === "campaign" ? (
        <>
          <header className='text-holder-confirmation'>
            <span className='icon icon-step-confirmation' />
            <div>
              <h2>Votre liste d'envoi a bien été créée !</h2>
              <p>
                Vos données sont en cours d'envoi vers la plateforme de
                communication... Retrouvez votre fichier téléchargé dans votre
                espace "Mes listes" et poursuivez la création de votre campagne.
              </p>
            </div>
          </header>
          <div className='white-section centered'>
            <NavLink
              to={`/myListsPush/${this.props.accountId}/`}
              className='btn btn-primary'
            >
              Accéder à ma liste d'envoi
            </NavLink>
            {this.getGoToFirstLink()}
          </div>
        </>
      ) : (
        <>
          <header className='text-holder-confirmation'>
            <span className='icon icon-step-confirmation' />
            <div>
              <h2>Votre liste a bien été exportée !</h2>
              <p>
                Pour accéder à votre liste et la télécharger cliquez sur le
                bouton ci-dessous.
              </p>
            </div>
          </header>
          <div className='white-section centered'>
            <NavLink
              to={`/myListsExport/${this.props.accountId}/`}
              className='btn btn-primary'
            >
              Accéder à ma liste exportée
            </NavLink>
            {this.getGoToFirstLink()}
          </div>
        </>
      );
    return (
      <div className='confirm-list-info' id='confirm'>
        {headerPanel}
        {this.getSupport()}
      </div>
    );
  }

  getSecondaryPanel() {
    return (
      <div className='detail-info'>
        <header className='text-holder'>
          <div>
            <h2>Votre contact chez Manageo</h2>
            <p>Une question ? Nous restons à votre disposition.</p>
          </div>
        </header>
        {this.getContactInfo()}
        <hr className='mb50' />
        <header className='text-holder'>
          <div>
            <h2>A découvrir aussi</h2>
          </div>
        </header>
        {this.getCompanyList()}
      </div>
    );
  }

  getGoToFirstLink() {
    if (!this.props.goToFirst) {
      return null;
    }
    return (
      <NavLink to={`/myTargeting/${this.props.accountId}/`} className='link'>
        Voir tous les ciblages
      </NavLink>
    );
  }

  goToFirst() {
    this.props.goToFirst();
  }

  render() {
    return (
      <Row className='PageContent h-100'>
        <Col sm={12} md={7} className='h-100 with-shadow pb-5 scrollAuto'>
          <>{this.getPrimaryPanel()}</>
        </Col>
        <Col sm={12} md={5} className='h-100 pb-5 scrollAuto'>
          <>{this.getSecondaryPanel()}</>
        </Col>
      </Row>
    );
  }
}
