import React from "react";
import "./DashboardContact.scss";

const DashboardContact = ({ component }) => {
  return (
    <div className='dashboard-contact-component'>
      <div className='contact-title'>{component.title}</div>
      <div className='contact-informations'>
        <div className='contact-name-status'>
          {(component.firstname || component.surname) && (
            <div className='name'>
              {component.firstname} {component.surname}
            </div>
          )}
          <div className='status'>{component.profession}</div>
        </div>
        <div className='contact-links'>
          <div className='link-entry'>
            <span className='value'>{component.email}</span>
            <a href={"mailto:" + component.email}>
              <span className={"icon icon-letter"} />
            </a>
          </div>
          <div className='link-entry'>
            <span className='value'>{component.phone}</span>
            <a href={"tel:" + component.phone}>
              <span className={"icon icon-phone"} />
            </a>
          </div>
        </div>
        {/*
        <div className="contact-picture">
          <img src="https://randomuser.me/api/portraits/men/17.jpg" alt=""/>
        </div>
        */}
      </div>
    </div>
  );
};

export default DashboardContact;
