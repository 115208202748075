import React, { Component } from "react";
import "./ColumnChartComponent.scss";
import { Bar, defaults } from "react-chartjs-2";
defaults.global.defaultFontFamily = "Raleway";

export default class ColumnChartComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: "Répartition par types de véhicules",
          fontColor: "#4a63a1",
          fontSize: "14",
          fontStyle: "bold",
        },
        scales: {
          xAxes: [
            {
              ticks: {
                autoSkip: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                precision: 0,
              },
            },
          ],
        },
        tooltips: {
          labels: false,
          displayColors: false,
          callbacks: {
            title: (el) => {
              return `${this.state.data.formattedToolTips[el[0].index]}`;
            },
            label: (tooltipItem, data) => {
              return false;
            },
          },
        },
      },
      data: {},
    };

    this.setNewData = this.setNewData.bind(this);
  }

  componentDidMount() {
    this.setNewData(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setNewData(newProps);
  }

  setNewData(props) {
    const newOptions = this.state.options;
    const data = {
      labels: [],
      titles: [],
      datasets: [
        {
          hoverBorderColor: "#e6e6e6",
          backgroundColor: [
            "#8dd674",
            "#f7776b",
            "#a9524e",
            "#c8d4e8",
            "#91a8d0",
            "#4e5c7b",
            "#fbe337",
            "#ffb400",
            "#AF33FF",
            "#FF33A5",
          ],
          data: [],
        },
      ],
      formattedToolTips: [],
    };

    // Set title
    newOptions.title.text = props.item.title;

    props.item.values.forEach((b) => {
      const legend = props.item.legends[b.nameValue];
      let label = props.item.legends[b.nameValue];
      data.datasets[0].data.push(b.value);
      data.titles.push(legend);

      if (props.item.title !== "Consommation globale de crédits") {
        if (label.length > 7) {
          const head = ([x, ...xs]) => x;
          let shortLabel = head(label.split(" "));
          if (shortLabel.length > 7) {
            shortLabel = shortLabel.slice(0, 7) + "...";
          }
          label = shortLabel;
        }
      }

      data.labels.push(label);
      data.formattedToolTips.push(b.formatValue);
    });

    this.setState({
      data,
      options: newOptions,
    });
  }

  render() {
    if (Object.keys(this.state.data).length === 0) {
      return null;
    }

    return (
      <div className='with-bg'>
        <Bar options={this.state.options} data={this.state.data} height={145} />
      </div>
    );
  }
}
