import React from "react";

// Styles
import "./pageLoaderComponent.scss";

const PageLoader = ({ opacity }) => {
  const setPreloadElements = () => {
    let elements = [];
    for (let j = 1; j < 10; j++) {
      elements.push(<div key={j} className={"sk-cube sk-cube-" + j}></div>);
    }
    return elements;
  };

  return (
    <div className={"page-loader " + (opacity ? "with-opacity" : "")}>
      <div className='loader'>
        <div className='sk-cube-grid'>{setPreloadElements()}</div>
      </div>
    </div>
  );
};

export default PageLoader;
