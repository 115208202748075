import React from "react";
import FiltersListComponent from "../FiltersList/FiltersListComponent";

export default function MyPreFiltersComponent({
  accountId,
  table,
  pageId,
  reloadPage,
  isMobile,
  sendQueryToApi,
}) {
  const getContentTable = (data) => (
    <FiltersListComponent
      pageId={pageId}
      filterType={"pre-filter"}
      headingTitle={"Mes préfiltres"}
      headingText={`Accédez à la liste complète de vos préfiltres ainsi qu'au processus de création de nouveaux préfiltres`}
      btntext={"Créer un préfiltre"}
      reloadItems={reloadPage}
      data={data}
      accountId={accountId}
      isMobile={isMobile}
      showAddButton={data.showAddButton}
      sendQueryToApi={sendQueryToApi}
    />
  );

  return table ? getContentTable(table) : "";
}
