import React, { useContext } from "react";
import "./Dashboard.scss";
import { Col, Row } from "react-flexbox-grid";
import DashboardContact from "./components/DashboardContact";
import DashboardAccount from "./components/DashboardAccount";
import ContentHeader from "../ContentHeader/ContentHeader";
import ColumnChartComponent from "../CreateList/components/ColumnChart/ColumnChartComponent";
import DoughnutGraphicComponent from "../CreateList/components/DoughnutGraphic/DoughnutGraphicComponent";
import ListsTableComponent from "../ListsTable/ListsTableComponent";
import { UserContext } from "../../context/UserContext";
import NoData from "../NoData/NoData";
import { NavLink } from "react-router-dom";

const Dashboard = (props) => {
  const primaryComponents = props.components.DashboardPrimaryPanel;
  const secondaryComponents = props.components.DashboardSecondaryPanel;
  const [user] = useContext(UserContext);

  const renderDashboardContact = () => {
    return (
      primaryComponents.contact &&
      primaryComponents.contact.authorized && (
        <DashboardContact
          accountId={props.accountId}
          component={primaryComponents.contact}
        />
      )
    );
  };

  const renderDashboardAccount = () => {
    return (
      primaryComponents.myAccount && (
        <DashboardAccount user={user} component={primaryComponents.myAccount} />
      )
    );
  };

  const renderChartComponents = (charts) => {
    return (
      <Row>
        {charts.map((chart, key) => {
          switch (chart.chartType) {
            case "doughnut":
              return (
                <Col key={key} className='card-item chart-item' sm={12} md={6}>
                  <DoughnutGraphicComponent item={chart} />
                </Col>
              );
            case "bar":
              return (
                <Col key={key} className='card-item chart-item' sm={12} md={6}>
                  <ColumnChartComponent item={chart} />
                </Col>
              );
            case "basic":
              return (
                <Col
                  key={key}
                  className='card-item chart-item basic-chart'
                  sm={12}
                  md={6}
                >
                  <div className='chart-content'>
                    <div className='chart-values'>
                      <div className='chart-title'>{chart.title}</div>
                      <div className='chart-value'>{chart.value}</div>
                    </div>
                  </div>
                </Col>
              );
            default:
              return (
                <Col
                  key={key}
                  className='card-item chart-item basic-chart'
                  sm={12}
                  md={6}
                >
                  <div className='chart-content'>
                    <div className='chart-values'>
                      <div className='chart-title mb-10'>{chart.title}</div>
                      <NoData />
                    </div>
                  </div>
                </Col>
              );
          }
        })}
      </Row>
    );
  };

  const renderTableComponents = (tables) => {
    return (
      <Row>
        {tables.map((table, key) => {
          return (
            <Col xs={12} key={key}>
              <ListsTableComponent
                hideSearch={true}
                table={table}
                accountId={props.accountId}
                pageId={table.id}
              />
            </Col>
          );
        })}
      </Row>
    );
  };

  const renderLinks = (links) => {
    return links.map((link, key) => {
      return (
        <NavLink
          key={key}
          to={`/${link.id}/${props.accountId}`}
          className='fake-link link-entry'
        >
          {link.title}
        </NavLink>
      );
    });
  };

  const renderStatistics = () => {
    return secondaryComponents.dashboardStatistics.map((statistic, key) => {
      return (
        <div key={key} className={"panel-block"}>
          <div className='title-links-container'>
            <h2>{statistic.title}</h2>

            {statistic.links && renderLinks(statistic.links)}
          </div>
          {statistic.charts && renderChartComponents(statistic.charts)}
          {statistic.tables && renderTableComponents(statistic.tables)}
        </div>
      );
    });
  };

  return (
    <Row className={"PageContent dashboard-view h-100"}>
      <ContentHeader accountId={props.accountId} />
      <Col sm={12} md={4} className='h-100 with-shadow primary-panel'>
        <>
          {renderDashboardAccount()}
          {renderDashboardContact()}
        </>
      </Col>
      <Col sm={12} md={8} className='h-100 pb-10 secondary-panel'>
        <>
          <div className='panel-blocks-container'>{renderStatistics()}</div>
        </>
      </Col>
    </Row>
  );
};

export default Dashboard;
