export default function createCriterion(el, content, exclude, conditionAnd) {
  let fullQueryObject = {
    query: [],
    queryCondition: "OR",
  };

  fullQueryObject.query.push(content.concepts[0].type === "DIMENSION" ? 0 : 1);
  fullQueryObject.query.push(el.ref ? el.ref : content.concepts[0].conceptName);

  if (el.operator) {
    fullQueryObject.query.push(el.operator);
  } else {
    switch (content.type) {
      case "SLIDER":
        fullQueryObject.query.push("NBT");
        break;
      case "AROUND_ME":
        fullQueryObject.query.push("EQ");
        break;
      case "CHECKBOX_LIST":
      case "CHECKBOX_TREE_LIST":
      case "CHECKBOX_TWO_CHOICES":
      case "CHECKBOX_ICON":
      case "MULTIPLE_TEXT":
      case "CHECKBOX":
      case "SEARCH":
        conditionAnd
          ? fullQueryObject.query.push("AND")
          : fullQueryObject.query.push("IN");
        break;
      default:
        fullQueryObject.query.push("EQ");
    }
  }

  if (Array.isArray(el)) {
    fullQueryObject.query.push(el);
  } else if (el.values) {
    fullQueryObject.query.push(el.values);
  } else {
    fullQueryObject.query.push([el.value]);
  }

  fullQueryObject.query.push(exclude ? 1 : 0);

  if (content[0] && content[0].logicalOperator) {
    fullQueryObject.queryCondition = content[0].logicalOperator;
  } else if (
    content.concepts &&
    content.concepts[0] &&
    content.concepts[0].logicalOperator
  ) {
    fullQueryObject.queryCondition = content.concepts[0].logicalOperator;
  }

  if (exclude) {
    if (fullQueryObject.queryCondition === "OR") {
      fullQueryObject.queryCondition = "AND";
    } else {
      fullQueryObject.queryCondition = "OR";
    }
  }

  return fullQueryObject;
}
