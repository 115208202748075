import React, { useState, useEffect } from "react";
import { Col, Row } from "react-flexbox-grid";
import Select from "react-select";
import "./RangeNumberComponent.scss";

const RangeNumberComponent = ({ handleClick, resetField }) => {
  const [selectedOption, setSelectedOption] = useState({
    value: "LT",
    label: "Supérieur à",
  });
  const initialState = {
    LT: "",
    GT: "",
    "BT-start": "",
    "BT-end": "",
    EQ: "",
  };
  const [inputs, setInputs] = useState(initialState);

  const options = [
    { value: "LT", label: "Supérieur à" },
    { value: "GT", label: "Inférieur à" },
    { value: "EQ", label: "Egal à" },
    { value: "BT", label: "Entre" },
  ];

  useEffect(() => {
    resetField && setInputs(initialState);
  }, [resetField, initialState]);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const handleInputChange = (val, name) => {
    const re = /^[0-9\b]+$/;
    if (val === "" || re.test(val)) {
      let newInputs = { ...inputs };
      newInputs[name] = val;
      setInputs(newInputs);
    }
  };

  const getInputs = () => {
    if (!selectedOption) {
      return null;
    }
    switch (selectedOption.value) {
      case "EQ":
      case "GT":
      case "LT":
        return (
          <div className='inputs-wrapper single'>
            <label className='input-holder'>
              <input
                type='number'
                value={inputs[selectedOption.value]}
                onChange={(e) =>
                  handleInputChange(e.target.value, selectedOption.value)
                }
              />
            </label>
          </div>
        );
      case "BT":
        return (
          <div className='inputs-wrapper'>
            <label className='input-holder'>
              <input
                type='number'
                value={inputs["BT-start"]}
                onChange={(e) => handleInputChange(e.target.value, "BT-start")}
              />
            </label>
            <span className='decorator'>et</span>
            <label className='input-holder'>
              <input
                type='number'
                value={inputs["BT-end"]}
                onChange={(e) => handleInputChange(e.target.value, "BT-end")}
              />
            </label>
          </div>
        );
      default:
        return null;
    }
  };

  const getValue = () => {
    let value = {};

    switch (selectedOption.value) {
      case "LT":
        value = {
          operator: "NGT",
          values: [inputs["LT"]],
        };
        break;
      case "GT":
        value = {
          operator: "NLT",
          values: [inputs["GT"]],
        };
        break;
      case "BT":
        value = {
          operator: "NBT",
          values: [inputs["BT-start"], inputs["BT-end"]],
        };
        break;
      case "EQ":
        value = {
          operator: "NEQ",
          values: [inputs["EQ"]],
        };
        break;
      default:
        return null;
    }
    return value;
  };

  const handleSubmit = () => {
    const value = getValue();
    if (value != null && value.values.length > 0 && value.values[0]) {
      handleClick(value, setInputs(initialState));
    }
  };

  const hisButtonEnable = () => {
    const value = getValue();
    if (value.values.length > 0 && value.values[0]) {
      return true;
    }
    return false;
  };

  const getSubmit = () => {
    if (!selectedOption) {
      return null;
    } else if (hisButtonEnable()) {
      return (
        <div className='btn-holder'>
          <button className='btn btn-primary ' onClick={() => handleSubmit()}>
            Valider
          </button>
        </div>
      );
    }
    return (
      <div className='btn-holder'>
        <button className='btn btn-light disabled' onClick={() => {}}>
          Valider
        </button>
      </div>
    );
  };

  return (
    <div className='modal-inputs range-component range-number-component'>
      <Row className={"main-container"}>
        <Col xs={12} sm={4} md={3}>
          <Select
            className='select'
            classNamePrefix='react-select'
            value={selectedOption}
            onChange={handleChange}
            options={options}
          />
        </Col>
        <Col xs={12} sm={8} md={9}>
          <div className='form-holder'>
            {getInputs()}
            {getSubmit()}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default RangeNumberComponent;
