import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios/index";

import "./SideBar.scss";

export default function SideBarComponent({
  menuItems,
  accountIdSelected,
  closesMobileMenu,
  mobileOpen,
  shownMenu,
  setShownMenu,
}) {
  const [expandedSubMenus, setExpandedSubMenus] = useState([]);
  const [user, setUser] = useState(null);
  const [wrapperRef, setWrapperRef] = useState(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef && !wrapperRef.contains(event.target)) {
        if (expandedSubMenus) {
          setExpandedSubMenus([]);
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    if (mobileOpen) {
      axios.get(process.env.REACT_APP_OIDC_URL + "account").then((response) => {
        setUser(response.data);
      });
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [accountIdSelected, mobileOpen, wrapperRef, expandedSubMenus]);

  const toggleMenu = () => {
    setShownMenu(!shownMenu);
  };

  const closeAllSubMenu = () => {
    setExpandedSubMenus([]);
    closesMobileMenu();
  };

  const expandSubMenu = (e, id, isItSubMenu) => {
    if (isItSubMenu) {
      e.preventDefault();
      const index = expandedSubMenus.indexOf(id);
      if (index > -1) {
        const array = [...expandedSubMenus];
        array.splice(index, 1);
        setExpandedSubMenus(array);
      } else {
        setExpandedSubMenus([id]);
      }
    } else {
      closeAllSubMenu();
    }
  };

  const renderMenuItems = (filterFunction) => {
    return menuItems.filter(filterFunction).map((item) => {
      if (item.type === "LinkSideBarMenu") {
        return (
          <li key={item.id} className={"item"}>
            <a
              className={"link"}
              target={"_blank"}
              rel='noopener noreferrer'
              href={item.url}
            >
              <span
                className={
                  "icon " +
                  (item.icon ? "icon-" + item.icon : "icon-" + item.id)
                }
              />
              <span className={"link-title"}>{item.title}</span>
            </a>
          </li>
        );
      } else {
        return (
          <li
            key={item.id}
            className={"item" + (item.links.length ? " hasSubMenu" : "")}
          >
            <NavLink
              to={`/${item.id}/${accountIdSelected}/`}
              className={
                "link " +
                (expandedSubMenus.indexOf(item.id) !== -1 ? "active" : "")
              }
              onClick={(e) => expandSubMenu(e, item.id, item.links.length)}
            >
              <span
                className={
                  "icon " +
                  (item.icon ? "icon-" + item.icon : "icon-" + item.id)
                }
              />
              <span className={"link-title"}>{item.title}</span>
            </NavLink>
            {item.links ? (
              <div
                className={
                  "subMenu" +
                  (expandedSubMenus.indexOf(item.id) !== -1 ? " opened" : "")
                }
              >
                <ul>
                  {item.links.map((link) => {
                    return (
                      <li key={link.id}>
                        <NavLink
                          onClick={closeAllSubMenu}
                          to={`/${link.id}/${accountIdSelected}/`}
                        >
                          {link.title}
                        </NavLink>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : null}
          </li>
        );
      }
    });
  };

  const renderMenuAccount = () => {
    return (
      <>
        <li className={"item hasSubMenu accountSubMenu"}>
          <NavLink
            to={`/moncompte/${accountIdSelected}/`}
            className={
              "link " +
              (expandedSubMenus.indexOf("myAccount") !== -1 ? "active" : "")
            }
            onClick={(e) => expandSubMenu(e, "myAccount", 2)}
          >
            <span className={"link-title"}>{user.email}</span>
          </NavLink>
          <div
            className={
              "subMenu" +
              (expandedSubMenus.indexOf("myAccount") !== -1 ? " opened" : "")
            }
          >
            <ul>
              <li>
                <NavLink
                  onClick={closeAllSubMenu}
                  to={`/myAccount/${accountIdSelected}/`}
                >
                  <span className='icon icon-changes'></span>
                  Gérer mes infos personnelles
                </NavLink>
              </li>
              <li>
                <NavLink to={`/logout`}>
                  <span className='icon icon-power'></span>
                  Déconnexion
                </NavLink>
              </li>
            </ul>
          </div>
        </li>
      </>
    );
  };

  return (
    <div className={"menu-holder " + (shownMenu ? "expanded" : "noExpanded")}>
      <ul className='list' ref={setWrapperRef}>
        {user && renderMenuAccount()}
        {menuItems && renderMenuItems((item) => !item.support)}
      </ul>
      <div className='list-down'>
        <ul className='list'>
          {menuItems && renderMenuItems((item) => item.support)}
        </ul>
        <button onClick={toggleMenu} className={"expand icon-arrow-right"} />
      </div>
    </div>
  );
}
