import React, { useState, useEffect } from "react";
import Modal from "react-responsive-modal";
import axios from "axios";
import "./../../ModalWindow/ModalWindow.scss";
import "./ModifyPasswordModal.scss";

export default function ModifyPasswordModal({ openModal, closeModal }) {
  const [update, setUpdate] = useState(false);

  const callModifyPassword = (e) => {
    e.preventDefault();
    axios.post(process.env.REACT_APP_OIDC_URL + "account/password");
    setUpdate(true);
    setTimeout(() => closeModal(), 3000);
  };

  useEffect(() => {
    setUpdate(false);
  }, [openModal]);

  const contentModal = update ? (
    <p>
      Votre demande de modification de mot de passe à bien été prise en compte.
      Un email vous a été envoyé à l'adresse associée à votre compte.
    </p>
  ) : (
    <>
      <p>
        Pour réinitialiser votre mot de passe, cliquer sur le bouton "Valider"
        et un email vous sera envoyé afin de procéder à la modification.
      </p>
      <div className='actions-holder'>
        <button className='btn btn-primary' onClick={callModifyPassword}>
          Valider
        </button>
        <button className='btn btn-secondary' onClick={closeModal}>
          Annuler
        </button>
      </div>
    </>
  );

  return (
    <Modal
      classNames={{ modal: "modal" }}
      open={openModal}
      onClose={closeModal}
      focusTrapped={false}
      center
    >
      <div className='single-tab-modal'>
        <header>
          <span>Modification du mot de passe</span>
        </header>
        <div className='single-tab-modal-content'>{contentModal}</div>
      </div>
    </Modal>
  );
}
