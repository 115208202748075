import React from "react";

import TableHeading from "./TableHeading";
import TableBody from "./TableBody";

export default function Table({
  table,
  sorting,
  handleClickSort,
  searchStr,
  rowsPerPage,
  activePage,
  accountId,
  pageId,
  setUpdating,
  deleteModalMessage,
  editResource,
  deleteModalTitle,
  refreshTable,
  setOpen,
  setIsTestCrmSuccess,
  setCrmType,
}) {
  return (
    <div className='colored-heading'>
      <div className='white-borders'>
        <table>
          <thead>
            <tr>
              <TableHeading
                headers={table.headers}
                sorting={sorting}
                handleClickSort={handleClickSort}
              />
            </tr>
          </thead>
          <tbody>
            <TableBody
              headers={table.headers}
              rowData={table.rowData}
              accountId={accountId}
              pageId={pageId}
              setUpdating={setUpdating}
              deleteModalMessage={deleteModalMessage}
              editResource={editResource}
              deleteModalTitle={deleteModalTitle}
              refreshTable={refreshTable}
              setOpen={setOpen}
              setIsTestCrmSuccess={setIsTestCrmSuccess}
              setCrmType={setCrmType}
            />
          </tbody>
        </table>
      </div>
    </div>
  );
}
