import React from "react";
import Dropdown from "../Dropdown/Dropdown";

export default function BilanConsolide({ bilanConsolide }) {
  const {
    year,
    ca,
    resultatExploitation,
    resultatNet,
    totalBilanNet,
    ecartAcquisition,
    totalDesDettes,
    resultatConsolide,
    ebe,
  } = bilanConsolide;

  return (
    bilanConsolide && (
      <Dropdown
        title={<h2>Bilan consolidé</h2>}
        content={
          <div className='row'>
            <table className='table-holder'>
              <thead>
                <tr>
                  <th></th>
                  <th>Bilan {year}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Chiffre d'affaires</td>
                  <td>{ca} €</td>
                </tr>
                <tr>
                  <td>Résultat d'exploitation</td>
                  <td>{resultatExploitation} €</td>
                </tr>
                <tr>
                  <td>Résultat net</td>
                  <td>{resultatNet} €</td>
                </tr>
                <tr>
                  <td>Total bilan net</td>
                  <td>{totalBilanNet} €</td>
                </tr>
                <tr>
                  <td>Ecart d'acquisition</td>
                  <td>{ecartAcquisition} €</td>
                </tr>
                <tr>
                  <td>Total des dettes</td>
                  <td>{totalDesDettes} €</td>
                </tr>
                <tr>
                  <td>Résultat consolidé</td>
                  <td>{resultatConsolide} €</td>
                </tr>
                <tr>
                  <td>EBE</td>
                  <td>{ebe} €</td>
                </tr>
              </tbody>
            </table>
          </div>
        }
      />
    )
  );
}
