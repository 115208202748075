import React from "react";
import Dropdown from "../Dropdown/Dropdown";

export default function Brand({ brand, goNext }) {
  const { count, lines } = brand;
  return (
    count > 0 && (
      <Dropdown
        title={<h2>Marques</h2>}
        content={
          <div className='donneeFinanciere'>
            <h3 className='leftText'>
              Nombre total de marques déposées : {count}
            </h3>
            {lines.length && (
              <div className='row'>
                <table className='table-holder'>
                  <thead>
                    <tr>
                      <th>Marque</th>
                      <th>Classe de dépôt de la marque</th>
                    </tr>
                  </thead>
                  <tbody>
                    {lines.map((line, index) => (
                      <tr key={index}>
                        <td>{line.title}</td>
                        <td>{line.value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            <div className='parameter'>
              <button
                className='btn btn-primary etabTitle-button'
                onClick={() => goNext()}
              >
                Exporter cette entreprise
              </button>
            </div>
          </div>
        }
      />
    )
  );
}
