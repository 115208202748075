import React from "react";
import useToggle from "../../../hooks/useToggle";

export default function Dropdown({ title, content, isClose }) {
  const [isOpen, setIsOpen] = useToggle(!isClose);

  return (
    <div className='dropdown'>
      <div className='dropdown-title' onClick={setIsOpen}>
        {title}
        <span
          className='icon-arrow-right'
          style={
            isOpen
              ? { transform: "rotate(-90deg)" }
              : { transform: "rotate(90deg)" }
          }
        />
      </div>
      {isOpen && <div className='dropdown-content'>{content}</div>}
    </div>
  );
}
