import React from "react";
import CreatingStatus from "./components/CreatingStatus/CreatingStatusComponent";
import Footer from "../Footer/FooterComponent";
import ConfirmContentSection from "./components/ConfirmContentSection/ConfirmContentSectionComponent";

export default function CreateListConfirmPageComponent({
  components,
  goTo,
  accountId,
  back,
  goToFirst,
  isMobile,
  requestParams,
}) {
  const getHeader = () =>
    components.contentHeader && (
      <CreatingStatus
        item={components.contentHeader}
        goTo={goTo}
        accountId={accountId}
      />
    );

  const getFooter = () =>
    components.wizardFooter && (
      <Footer item={components.wizardFooter} goBack={back} />
    );

  return (
    components && (
      <div className='h-100'>
        {getHeader()}
        <ConfirmContentSection
          goToFirst={goToFirst}
          accountId={accountId}
          isMobile={isMobile}
          fileFormat={requestParams.fileFormat}
        />
        {getFooter()}
      </div>
    )
  );
}
