import React from "react";
import "./Checkbox.scss";

export default function CheckBox({
  checked,
  handleCheckChildElement,
  id,
  value,
  children,
}) {
  return (
    <label className={checked === true ? "checked" : ""}>
      <div className={"custom-checkbox " + (checked === true ? "checked" : "")}>
        <input
          key={id}
          onChange={handleCheckChildElement}
          type='checkbox'
          checked={checked === undefined ? false : checked}
          value={value}
        />
      </div>
      {children}
    </label>
  );
}
