import React, { useState, createContext, useEffect } from "react";
import axios from "axios/index";

export const UserContext = createContext();

export const UserProvider = (props) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    //Getting user informations on component did mount
    axios.get(process.env.REACT_APP_OIDC_URL + "account").then((response) => {
      setUser(response.data);
    });
  }, []);

  return (
    <UserContext.Provider value={[user, setUser]}>
      {props.children}
    </UserContext.Provider>
  );
};
