import React from "react";
import Dropdown from "../Dropdown/Dropdown";

export default function FinancialData({ datas, goNext }) {
  const {
    year1,
    year2,
    ca1,
    ca2,
    caV,
    pe1,
    pe2,
    peV,
    mc1,
    mc2,
    mcV,
    mb1,
    mb2,
    mbV,
    va1,
    va2,
    vaV,
    ms1,
    ms2,
    msV,
  } = datas;

  return (
    datas && (
      <Dropdown
        title={<h2>Données financières</h2>}
        content={
          <div className='donneeFinanciere'>
            {
              <h3 className='leftText'>
                {year1
                  ? `Date du dernier bilan connu : ${year1}`
                  : "Cette entreprise ne publie pas son bilan ou a décidé de le garder confidentiel"}
              </h3>
            }
            {year1 && (
              <>
                <div className='row'>
                  <table className='table-holder'>
                    <thead>
                      <tr>
                        <th>Solde Intermédiaires de Gestion</th>
                        <th>{year1}</th>
                        <th>{year2}</th>
                        <th>Variation</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Chiffre d'affaires</td>
                        <td>{ca1}</td>
                        <td>{ca2}</td>
                        <td>{caV}</td>
                      </tr>
                      <tr>
                        <td>Production de l'exercice</td>
                        <td>{pe1}</td>
                        <td>{pe2}</td>
                        <td>{peV}</td>
                      </tr>
                      <tr>
                        <td>Marge commerciale</td>
                        <td>{mc1}</td>
                        <td>{mc2}</td>
                        <td>{mcV}</td>
                      </tr>
                      <tr>
                        <td>Marge brute</td>
                        <td>{mb1}</td>
                        <td>{mb2}</td>
                        <td>{mbV}</td>
                      </tr>
                      <tr>
                        <td>Valeur ajoutée</td>
                        <td>{va1}</td>
                        <td>{va2}</td>
                        <td>{vaV}</td>
                      </tr>
                      <tr>
                        <td>Masse salariale</td>
                        <td>{ms1}</td>
                        <td>{ms2}</td>
                        <td>{msV}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <h3 className='leftText'>
                  Pour obtenir le détail des données financières connues pour
                  cette société, exportez sa fiche sous forme de fichier.
                  Accédez à l'étape de paramétrage pour sélectionner les données
                  que vous souhaitez intégrer à votre export.
                </h3>
                <div className='parameter'>
                  <button
                    className='btn btn-primary etabTitle-button'
                    onClick={() => goNext()}
                  >
                    Paramétrer mon export
                  </button>
                </div>
              </>
            )}
          </div>
        }
      />
    )
  );
}
