import React, { Component } from "react";

import "./CheckBoxTwoChoicesModalComponent.scss";
import ModalFooter from "../../ModalFooter/ModalFooterComponent";
import CheckBox from "../../../../../fields/Checkbox/Checkbox";
import { Row, Col } from "react-flexbox-grid";

class CheckBoxTwoChoicesView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      values: this.props.content.concepts[0].values,
      optionsChecked: [],
      exclude: false,
    };

    this.handler = this.handler.bind(this);
    this.handleCheckChildElement = this.handleCheckChildElement.bind(this);
    this.ifItChecked = this.ifItChecked.bind(this);
  }

  componentDidMount() {
    if (this.props.concept && this.props.concept.length > 0) {
      let newChecked = [];

      let filters = this.props.concept[0].filters;

      filters.forEach((f, i) => {
        f.expressions.forEach((expression) => {
          const fVal = {
            value: expression,
            label: this.getValueLabel(expression),
          };
          newChecked.push(fVal);
        });
      });

      let newEx = filters[0].not;

      this.setState({
        optionsChecked: newChecked,
        exclude: newEx,
      });
    }
  }

  updateQuery() {
    const checkedValues = [];

    this.state.optionsChecked.forEach((option) => {
      checkedValues.push(option.value);
    });

    this.props.updateQuery(
      checkedValues.length ? [checkedValues] : [],
      this.props.content,
      this.state.exclude,
      this.props.tab,
      this.props.modal
    );
  }

  getValueLabel(val) {
    let label = "";
    if (this.state.values) {
      this.state.values.forEach((v) => {
        if (v.value === val) {
          label = v.label;
        }
      });
    }
    return label;
  }

  handleCheckChildElement(event, elem) {
    let checkedArray = this.state.optionsChecked;
    let index = -1;

    Object.keys(checkedArray).forEach((key) => {
      if (checkedArray[key].value === elem.value) {
        index = key;
      }
    });

    if (index > -1) {
      checkedArray.splice(index, 1);
    } else {
      checkedArray.push(elem);
    }

    this.setState(
      {
        optionsChecked: checkedArray,
      },
      () => {
        this.updateQuery();
      }
    );
  }

  ifItChecked(i) {
    if (!this.state.optionsChecked) {
      return null;
    }
    const newArray = this.state.optionsChecked.filter((e) => {
      return e.value === i;
    });
    return newArray.length > 0;
  }

  getCheckboxes(checkbox_list) {
    if (!checkbox_list) {
      return null;
    }
    return checkbox_list.map((el, i) => {
      return (
        <li key={i}>
          <CheckBox
            handleCheckChildElement={(e) => {
              this.handleCheckChildElement(e, el);
            }}
            checked={this.ifItChecked(el.value)}
            value={el.value}
          >
            {this.ifItChecked(el.value)}
            {el.label}
          </CheckBox>
        </li>
      );
    });
  }

  handler(checked) {
    this.setState(
      {
        exclude: checked,
      },
      () => {
        if (this.state.optionsChecked.length) {
          this.updateQuery();
        }
      }
    );
  }

  getFooter() {
    return (
      <ModalFooter
        handleChange={this.handler}
        exclude={this.state.exclude}
        option={this.props.content.option}
      />
    );
  }

  render() {
    return (
      <div className='modal'>
        <div
          className={
            "modal-content modal-two-choices" +
            (this.state.exclude ? " exclude" : " include")
          }
        >
          <Row className='checkboxes-wrapper'>
            <Col xs={12}>
              <ul className='checkboxes-list'>
                {this.getCheckboxes(this.state.values)}
              </ul>
            </Col>
          </Row>
        </div>
        {this.getFooter()}
      </div>
    );
  }
}
export default CheckBoxTwoChoicesView;
