import React from "react";
import Dropdown from "../Dropdown/Dropdown";

export default function Leader({ leaders }) {
  return (
    !!leaders?.length && (
      <Dropdown
        title={<h2>Dirigeants</h2>}
        content={
          <ul className='row leaders'>
            {leaders.map((leader, key) => (
              <li className='col' key={key}>
                <span className={`icon icon-${leader.picto}`} />
                <p>{leader.famillyFunction}</p>
                <p>
                  {leader.prenom} {leader.nom}
                </p>
                <p>{leader.function}</p>
              </li>
            ))}
          </ul>
        }
      />
    )
  );
}
