import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import "./UserBar.scss";

class UserBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLogout: false,
      opened: false,
    };

    this.wrapperRef = null;
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  }

  routingFunction = (param) => {
    this.props.history.push({
      pathname: `/logout`,
      state: param,
    });
  };

  handleLogout = (event) => {
    event.preventDefault();
    this.routingFunction();
  };

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  toggleDropdown = () => {
    this.setState({
      opened: !this.state.opened,
    });
  };

  hideDropdown = () => {
    this.setState({
      opened: false,
    });
  };

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        opened: false,
      });
    }
  };

  render() {
    if (!this.props.account) {
      return null;
    }
    return (
      <div className='info-holder'>
        <div className='user-box'>
          <div
            className='username'
            onClick={this.toggleDropdown}
            ref={this.setWrapperRef}
          >
            <span>{this.props.account.fullName}</span>
            <div className='img-holder'>
              <img
                src={this.props.account.imageUrl}
                alt='Profile Icon'
                className='avatar'
              />
            </div>
          </div>
          <div
            className={"user-dropdown " + (this.state.opened ? "active" : "")}
          >
            <ul>
              <li>
                <Link
                  className='link'
                  to={{
                    pathname: `/myAccount/${this.props.accountId}`,
                    state: { account: this.props.account },
                  }}
                >
                  <span className='icon icon-changes'></span>
                  Gérer mes infos personnelles
                </Link>
              </li>
              <li>
                <span className='link' onClick={this.handleLogout}>
                  <span className='icon icon-power'></span>
                  Déconnexion
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(UserBar);
