import React from "react";

const SelectedValuesBox = ({ concepts, removeConcept }) => {
  return (
    <div className='modal-box'>
      <>
        <ul className='concepts-list'>
          {concepts.map((e, i) => {
            return (
              <li key={i}>
                <span
                  className='remove-item'
                  onClick={(ev) => removeConcept(i)}
                >
                  &#10005;
                </span>{" "}
                {e.label}
              </li>
            );
          })}
        </ul>
      </>
    </div>
  );
};

export default SelectedValuesBox;
