import React, { useState, useEffect } from "react";
import "./ModalConditionComponent.scss";
import Switch from "react-switch";

const ModalCondition = ({ handleChange }) => {
  const [conditionAnd, setConditionAnd] = useState(false);

  useEffect(() => {
    handleChange(conditionAnd);
  }, [conditionAnd, handleChange]);

  const switchConditionAnd = () => {
    setConditionAnd(true);
  };

  const switchConditionOr = () => {
    setConditionAnd(false);
  };

  return (
    <div className={"modal-footer"}>
      <div className='switch-modal'>
        <div className='switch-label label-on'>
          <span onClick={switchConditionOr}>Ou</span>
        </div>
        <div className='switch'>
          <Switch
            height={12}
            width={35}
            handleDiameter={19}
            uncheckedIcon={false}
            checkedIcon={false}
            onChange={() => setConditionAnd(!conditionAnd)}
            checked={conditionAnd}
            boxShadow={"0 0 2px 3px #dde2eb"}
            activeBoxShadow={""}
            offColor={"#32a31a"}
            onColor={"#32a31a"}
          />
        </div>
        <div className='switch-label label-off'>
          <span onClick={switchConditionAnd}>Et</span>
        </div>
      </div>
    </div>
  );
};

export default ModalCondition;
