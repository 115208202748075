import React, { Component } from "react";

import "./myLocationModalComponent.scss";
import ModalFooter from "../../ModalFooter/ModalFooterComponent";
import { Row, Col } from "react-flexbox-grid";
import Select from "react-select";
import Axios from "axios";
import CitiesAutocomplete from "../citiesAutocomplete/citiesAutocomplete";
import ResetFilter from "../reset/ResetFilters";

const options = [
  { value: "10", label: "10 km" },
  { value: "25", label: "25 km" },
  { value: "50", label: "50 km" },
  { value: "100", label: "100 km" },
];

const LocationiqToken = "08ccdcf7353f9a";

const defaultState = {
  inputValue: "",
  latitude: "",
  longitude: "",
  exclude: false,
  selectedOption: { value: "10", label: "10 km" },
  concepts: [],
  loadLocation: false,
  located: false,
  isSearching: false,
};

class MyLocation extends Component {
  BASE_DOMAIN = process.env.REACT_APP_API_URL;

  constructor(props) {
    super(props);
    this.state = defaultState;

    this.timer = null;

    this.handler = this.handler.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.changeLocation = this.changeLocation.bind(this);
    this.handleSelectCity = this.handleSelectCity.bind(this);
    this.refreshData = this.refreshData.bind(this);
  }

  componentDidMount() {
    const props = this.props;
    if (props.concept) {
      if (props.concept[0] && props.concept[0].filters) {
        const activeFilter = props.concept[0].filters[0];
        this.reverseCoords(
          activeFilter.expressions[0],
          activeFilter.expressions[1]
        ).then((response) => {
          this.setState({
            longitude: activeFilter.expressions[0],
            latitude: activeFilter.expressions[1],
            loadLocation: false,
            located: true,
            inputValue: response.data.address.city
              ? response.data.address.city
              : response.data.address.county,
          });
        });

        options.forEach((option) => {
          if (option.value === activeFilter.expressions[2]) {
            this.setState({
              selectedOption: option,
            });
          }
        });
      }
    }
  }

  forwardGeocoding(postalCode) {
    return Axios({
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      url:
        "https://eu1.locationiq.com/v1/search.php?key=" +
        LocationiqToken +
        "&postalcode=" +
        postalCode +
        "&countrycodes=fr&format=json",
      async: true,
      crossDomain: true,
    });
  }

  reverseCoords(lat, lon) {
    if (typeof lat === "string" && lat.includes(",")) {
      const splitted = lat.split(",");
      lat = splitted[0];
      lon = splitted[1];
    }
    return Axios({
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      url:
        "https://eu1.locationiq.com/v1/reverse.php?key=" +
        LocationiqToken +
        "&lat=" +
        lat +
        "&lon=" +
        lon +
        "&format=json",
      async: true,
      crossDomain: true,
    });
  }

  handleClick() {
    let values = [
      [
        this.state.latitude.toString(),
        this.state.longitude.toString(),
        this.state.selectedOption.value,
      ],
    ];
    this.props.updateQuery(
      values,
      this.props.content,
      this.state.exclude,
      this.props.tab,
      this.props.modal
    );
  }

  handler(checked) {
    this.setState(
      {
        exclude: checked,
      },
      () => {
        let values = [
          [
            this.state.latitude.toString(),
            this.state.longitude.toString(),
            this.state.selectedOption.value,
          ],
        ];
        this.props.updateQuery(
          values,
          this.props.content,
          this.state.exclude,
          this.props.tab,
          this.props.modal
        );
      }
    );
  }

  getSubmit() {
    if (!this.state.selectedOption) {
      return null;
    }
    return (
      <div className='btn-holder'>
        <button
          className={
            "btn btn-primary" + (!this.state.located ? " disable" : "")
          }
          onClick={this.handleClick}
        >
          Valider
        </button>
        <ResetFilter handleDelete={this.refreshData} />
      </div>
    );
  }

  handleChange(selectedOption) {
    this.setState({ selectedOption });
  }

  getFooter() {
    return (
      <ModalFooter
        handleChange={this.handler}
        exclude={this.state.exclude}
        option={this.props.content.option}
      />
    );
  }

  changeLocation() {
    this.setState({
      isSearching: true,
      located: false,
    });
  }

  getInputValue(lat, lon) {
    this.reverseCoords(lat, lon).then((response) => {
      this.setState({
        loadLocation: false,
        located: true,
        inputValue: response.data.address.city
          ? response.data.address.city
          : response.data.address.state,
      });
    });
  }

  getPosition() {
    const location = navigator && navigator.geolocation;

    this.setState(
      {
        loadLocation: true,
      },
      () => {
        if (location) {
          location.getCurrentPosition(
            (position) => {
              this.setState(
                {
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude,
                },
                () => {
                  this.getInputValue(this.state.latitude, this.state.longitude);
                }
              );
            },
            (error) => {
              console.error(error);
              this.setState({
                latitude: "err-latitude",
                longitude: "err-longitude",
                loadLocation: false,
              });
            }
          );
        }
      }
    );
  }

  handleSelectCity(city) {
    const cityZipcode = city.name.split(" ")[0];
    this.setState(
      {
        loadLocation: true,
        located: false,
        inputValue: "",
      },
      () => {
        this.forwardGeocoding(cityZipcode).then((response) => {
          this.setState({
            latitude: response.data[0].lat,
            longitude: response.data[0].lon,
            located: true,
            inputValue: city.code,
            isSearching: false,
          });
        });
      }
    );
  }

  refreshData() {
    this.setState(defaultState);
    this.props.updateQuery(
      [],
      this.props.content,
      this.state.exclude,
      this.props.tab,
      this.props.modal
    );
  }

  render() {
    if (!this.props) {
      return null;
    }
    return (
      <div className='modal modal-myLocation'>
        <div
          className={
            "modal-content" + (this.state.exclude ? " exclude" : " include")
          }
        >
          <div className='modal-inputs'>
            <Row>
              <Col md={12}>
                <Row className='mb-5'>
                  {!this.state.isSearching && (
                    <Col sm={12} md={7} className='mb-5'>
                      <h3>Vous avez été localisé à</h3>
                      <div
                        className={
                          "geolocation-holder" +
                          (this.state.inputValue ? " with-value" : "")
                        }
                      >
                        <span
                          onClick={() => this.getPosition()}
                          className={
                            "fake-input-btn" +
                            (this.state.loadLocation === true ? " loading" : "")
                          }
                        >
                          Me localiser
                        </span>
                        <span className='fake-input'>
                          <span className='text'>{this.state.inputValue}</span>
                        </span>
                        <span className='icon-map-pin'></span>
                      </div>
                    </Col>
                  )}
                  {this.state.isSearching && (
                    <Col sm={12} md={7} className='mb-5'>
                      <h3>Recherchez une Ville/Code postale</h3>
                      <CitiesAutocomplete
                        conceptName={"mlist.ville"}
                        handleSelectCity={this.handleSelectCity}
                      />
                    </Col>
                  )}
                  <Col sm={12} md={4} className='mb-5'>
                    <h3>Étendre mon rayon</h3>
                    <Select
                      className='select'
                      classNamePrefix='react-select'
                      value={this.state.selectedOption}
                      onChange={this.handleChange}
                      options={options}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    {this.state.located && !this.state.isSearching && (
                      <span
                        onClick={this.changeLocation}
                        className={"fake-link"}
                      >
                        Changer de localisation
                      </span>
                    )}
                    {this.getSubmit()}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
        {this.getFooter()}
      </div>
    );
  }
}

export default MyLocation;
