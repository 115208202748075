import React, { Component } from "react";
import "./CheckBoxTreeListModalComponent.scss";
import ModalFooter from "../../ModalFooter/ModalFooterComponent";
import ModalSearch from "../../ModalSearch/ModalSearchFilterCpmponent";
import CheckboxTree from "react-checkbox-tree";
import ResetFilter from "../reset/ResetFilters";

class CheckBoxTreeListView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      concepts: this.props.content.concepts,
      groups: this.props.content.groups,
      optionsChecked: [],
      exclude: false,
      filterStr: "",
      checked: [],
    };

    this.handler = this.handler.bind(this);
    this.updateQuery = this.updateQuery.bind(this);
    this.handlerSearch = this.handlerSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  componentDidMount() {
    if (this.props.concept.length) {
      let newChecked = [];
      let filters = this.props.concept[0].filters;
      const groupsVals = [];

      this.props.content.groups.forEach((g) => {
        groupsVals.push(g.valueRef);
      });

      filters.forEach((filter) => {
        filter.expressions.forEach((expression) => {
          // Check if it group
          if (groupsVals.indexOf(expression) > -1) {
            let values = [];
            this.props.content.groups.forEach((gr) => {
              if (gr.valueRef === expression) {
                gr.values.forEach((v) => {
                  values.push(v.valueRef);
                });
              }
            });
            newChecked.push(...values);
          } else {
            newChecked.push(expression);
          }
        });
      });

      // Setting exclusion when reopening modal
      this.setState({
        exclude: filters[0].not,
      });

      this.setState({
        checked: newChecked,
      });
    }
  }

  getAllItems() {
    const allItems = {};

    this.state.concepts.forEach((c) => {
      allItems[c.conceptName] = {};
      c.values.forEach((v) => {
        allItems[c.conceptName][v.value] = v;
      });
    });

    let items = this.state.groups.map((gt) => {
      let elem = {
        value: allItems[gt.conceptRef][gt.valueRef].value,
        label: allItems[gt.conceptRef][gt.valueRef].label,
        conceptRef: gt.conceptRef,
        children: [],
      };
      gt.values.forEach((val) => {
        if (allItems[val.conceptRef]) {
          if (allItems[val.conceptRef][val.valueRef]) {
            elem.children.push({
              value: allItems[val.conceptRef][val.valueRef].value,
              label: allItems[val.conceptRef][val.valueRef].label,
              conceptRef: val.conceptRef,
            });
          }
        }
      });
      return elem;
    });

    return items;
  }

  getCheckboxes() {
    if (!this.state.groups) {
      return null;
    }
    let items = this.getAllItems();
    items = items.filter((el) => {
      // filter childrens
      el.children = el.children.filter((c) => {
        if (
          el.label.toLowerCase().indexOf(this.state.filterStr) > -1 ||
          c.label.toLowerCase().indexOf(this.state.filterStr) > -1
        ) {
          return el;
        } else {
          return null;
        }
      });
      // return filtered items
      return (
        el.children.length ||
        el.label.toLowerCase().indexOf(this.state.filterStr) > -1
      );
    });

    return (
      <CheckboxTree
        nodes={items}
        checked={this.state.checked}
        expanded={this.state.expanded}
        onCheck={(checked) => this.handleCheck(checked)}
        onExpand={(expanded) => this.setState({ expanded })}
      />
    );
  }

  getSelectedName(el) {
    return this.state.concepts.map((c) => {
      return c.values.map((v) => {
        return v.value === el ? v.label : "";
      });
    });
  }

  getSearch() {
    return <ModalSearch handleChange={this.handlerSearch} />;
  }

  handlerSearch(s) {
    let newExpanded = [];
    if (s.length > 0 && this.state.groups != null) {
      this.state.groups.forEach(function (el) {
        newExpanded.push(el.valueRef);
      });
    }
    this.setState({
      filterStr: s.toLowerCase(),
      expanded: newExpanded,
    });
  }

  getCheckedItems() {
    const concepts = this.getAllItems();
    const checkedItems = [];

    concepts.forEach((con) => {
      let allSelected = true;
      const selectedItems = [];

      if (con.children.length) {
        con.children.forEach((chil) => {
          if (this.state.checked.indexOf(chil.value) > -1) {
            selectedItems.push({
              value: chil.value,
              ref: chil.conceptRef[0],
            });
          } else {
            allSelected = false;
          }
        });

        if (allSelected) {
          checkedItems.push({
            value: con.value,
            ref: con.conceptRef[0],
          });
        } else {
          checkedItems.push(...selectedItems);
        }
      } else {
        if (this.state.checked.indexOf(con.value) > -1) {
          checkedItems.push({
            value: con.value,
            ref: con.conceptRef[0],
          });
        }
      }
    });

    let newCheckItemsArray = [];

    const contentConcepts = this.props.content.concepts;

    contentConcepts.forEach((concept, conceptKey) => {
      newCheckItemsArray.push({ values: [], ref: concept.conceptName });
      checkedItems
        .filter((checkedItem) => checkedItem.ref === concept.conceptName)
        .forEach((checkedItem) => {
          newCheckItemsArray[conceptKey].values.push(checkedItem.value);
        });
    });

    newCheckItemsArray = newCheckItemsArray.filter(
      (conceptArray) => conceptArray.values.length
    );

    return newCheckItemsArray;
  }

  updateQuery() {
    this.props.updateQuery(
      this.getCheckedItems(),
      this.props.content,
      this.state.exclude,
      this.props.tab,
      this.props.modal
    );
  }

  handleCheck(check) {
    this.setState(
      {
        checked: check,
      },
      () => {
        this.updateQuery();
      }
    );
  }

  handleReset() {
    this.setState(
      {
        checked: [],
      },
      () => {
        this.updateQuery();
      }
    );
  }

  handler(checked) {
    this.setState(
      {
        exclude: checked,
      },
      () => {
        if (this.getCheckedItems().length) {
          this.updateQuery();
        }
      }
    );
  }

  getFooter() {
    return (
      <ModalFooter
        handleChange={this.handler}
        exclude={this.state.exclude}
        option={this.props.content.option}
      />
    );
  }

  render() {
    return (
      <div className='modal'>
        <ResetFilter handleDelete={this.handleReset} />
        <div
          className={
            "modal-content" + (this.state.exclude ? " exclude" : " include")
          }
        >
          {this.getSearch()}
          <div className='checkboxes-wrapper'>
            <div className='col col12'>
              <div className='col-holder '>
                <>
                  <div className='checkboxes-list '>{this.getCheckboxes()}</div>
                </>
              </div>
            </div>
          </div>
        </div>
        {this.getFooter()}
      </div>
    );
  }
}

export default CheckBoxTreeListView;
