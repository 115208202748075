import React from "react";

export default function EstablissementTitle({ establishmentHeader, goNext }) {
  const { rs, address, activity } = establishmentHeader;

  return (
    rs && (
      <div className='etabTitle'>
        <div className='row'>
          <div className='col'>
            <h2>{rs}</h2>
            <p>{address}</p>
            <p>{activity}</p>
          </div>
          <button
            className='btn btn-primary etabTitle-button'
            onClick={() => goNext()}
          >
            Exporter cette entreprise
          </button>
        </div>
      </div>
    )
  );
}
