import React, { useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import DatePicker from "react-datepicker";
import Select from "react-select";
import "./RangeDateComponent.scss";

const RangeDateComponent = (props) => {
  const [selectedOption, setSelectedOption] = useState({
    value: "DLT",
    label: "Avant",
  });
  const [dates, setDates] = useState({
    "DLT-Date": new Date(),
    "DGT-Date": new Date(),
    "DBT-start-Date": new Date(),
    "DBT-end-Date": new Date(),
  });

  const options = [
    { value: "DLT", label: "Avant" },
    { value: "DGT", label: "Après" },
    { value: "DBT", label: "Période de" },
  ];

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const handleDateChange = (e, name) => {
    let newDates = { ...dates };
    newDates[name] = e;
    setDates(newDates);
  };

  const getInputs = () => {
    if (!selectedOption) {
      return null;
    }
    switch (selectedOption.value) {
      case "DLT":
        return (
          <div className='inputs-wrapper single'>
            <DatePicker
              dateFormat='dd/MM/yyyy'
              selected={dates["DLT-Date"]}
              onChange={(e) => handleDateChange(e, "DLT-Date")}
            />
          </div>
        );
      case "DGT":
        return (
          <div className='inputs-wrapper single'>
            <DatePicker
              dateFormat='dd/MM/yyyy'
              selected={dates["DGT-Date"]}
              onChange={(e) => handleDateChange(e, "DGT-Date")}
            />
          </div>
        );
      case "DBT":
        return (
          <div className='inputs-wrapper'>
            <DatePicker
              dateFormat='dd/MM/yyyy'
              selected={dates["DBT-start-Date"]}
              onChange={(e) => handleDateChange(e, "DBT-start-Date")}
            />
            <span className='decorator'>à</span>
            <DatePicker
              dateFormat='dd/MM/yyyy'
              selected={dates["DBT-end-Date"]}
              onChange={(e) => handleDateChange(e, "DBT-end-Date")}
            />
          </div>
        );
      default:
        return null;
    }
  };

  const formatDate = (d) => {
    const date = new Date(d);
    const day = `0${date.getDate()}`.slice(-2);
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const handleSubmit = () => {
    let value = {
      operator: selectedOption.value,
      values: [],
    };

    switch (value.operator) {
      case "DLT":
        value.values.push(formatDate(dates["DLT-Date"]));
        break;
      case "DGT":
        value.values.push(formatDate(dates["DGT-Date"]));
        break;
      case "DBT":
        value.values.push(formatDate(dates["DBT-start-Date"]));
        value.values.push(formatDate(dates["DBT-end-Date"]));
        break;
      default:
        return null;
    }

    props.handleClick(value);
  };

  const getSubmit = () => {
    if (!selectedOption) {
      return null;
    }
    return (
      <div className='btn-holder'>
        <button className='btn btn-primary' onClick={() => handleSubmit()}>
          Valider
        </button>
      </div>
    );
  };

  return (
    <div className='modal-inputs range-component range-date-component'>
      <Row className={"main-container"}>
        <Col xs={12} sm={4} md={3}>
          <Select
            className='select'
            classNamePrefix='react-select'
            value={selectedOption}
            onChange={handleChange}
            options={options}
          />
        </Col>
        <Col xs={12} sm={8} md={9}>
          <div className='form-holder'>
            {getInputs()}
            {getSubmit()}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default RangeDateComponent;
